import SelectWrap from './styles';

import Modal from 'components/Modal';
import React, { useState, useEffect, useRef } from 'react';

export default ({
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  topRightCornerRadius,
  topLeftCornerRadius,
  bottomLeftCornerRadius,
  bottomRightCornerRadius,
  width,
  options,
  formikProps,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(null);
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const input = useRef(null);

  useEffect(() => {
    if (input.current.value || value) setActive(true);
  }, [value]);

  const { errors, touched } = formikProps;

  return (
    <SelectWrap
      active={active}
      topLeftCornerRadius={topLeftCornerRadius}
      topRightCornerRadius={topRightCornerRadius}
      bottomLeftCornerRadius={bottomLeftCornerRadius}
      bottomRightCornerRadius={bottomRightCornerRadius}
      width={width}
    >
      <label htmlFor={name}>{label}</label>
      <button
        type="button"
        ref={input}
        id={name}
        onChange={e => {
          if (onChange) onChange(e);
        }}
        onClick={e => setModalIsOpened(true)}
        {...props}
      />
      <div className="value">{value}</div>
      <Modal open={modalIsOpened} handleClose={() => setModalIsOpened(false)}>
        {options &&
          options.map(option => (
            <button
              key={option.value}
              onClick={() => {
                setValue(option.label);
                formikProps.setFieldTouched(name);
                formikProps.setFieldValue(name, option.value, true);
                setModalIsOpened(false);
              }}
            >
              {option.render}
            </button>
          ))}
      </Modal>
      {errors[name] && touched[name] && (
        <div className="errors">{errors[name]}</div>
      )}
    </SelectWrap>
  );
};
