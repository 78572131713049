import styled from 'styled-components';

export const Wrap = styled.section`
  width: 100%;
  background: #fafafa;
  color: #222;
  min-height: 400px;
  padding: 56px 0;
  text-align: center;

  .container {
    position: relative;
    width: 95%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 63px;

    > div {
      margin: 0 auto;
      position: relative;
      background: white;
      padding: 40px 5vw;
      padding-top: 95px;
      border-radius: 15px;
      box-shadow: 0 18px 14px rgba(0, 0, 0, 0.2);
      max-width: 100%;

      > svg {
        position: absolute;
        top: -65px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;
        display: inline-block;
      }

      > div {
        margin: 20px;

        button {
          min-width: 150px;
          height: auto;
          padding: none;
          border: none;
          background: none;
          cursor: pointer;
          margin: 50px 10px;

          img {
            height: 80px;
          }

          &.upcoming {
            position: relative;
            opacity: 0.4;

            &::after {
              content: 'Breve';
              position: absolute;
              bottom: -15px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
`;

export const H2 = styled.h2`
  position: relative;
  font-family: Fugaz One;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 68px;
  text-align: center;
  color: #0e3182;

  /** Estilização para dispositivos móveis */
  @media screen and (max-width: 1060px) {
    display: inline-block;
    margin: 20px auto;
  }

  @media screen and (max-width: 540px) {
    display: inline-block;
    margin: 20px auto;
    font-size: 35px;
  }
`;
