import { SlideWrap } from './styles';

import Button from '../../../../components/Button';

import React from 'react';

const Slide = () => {
  return (
    <SlideWrap>
      <img
        src="/assets/img/apx_por_carros_banner_1.png"
        alt="Imagem única de banner principal: Solicite sua lavagem!"
      ></img>
      <Button
        className="bounceInLeft"
        onClick={e => {
          document.location += '#solicitar-lavagem';
          return false;
        }}
      >
        Solicitar Lavagem
      </Button>
      <span>
        *Atendimento apenas em região de Lauro de Freitas, Salvador e Camaçari -
        BA
      </span>
    </SlideWrap>
  );
};

export default Slide;
