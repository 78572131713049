import Option from './styles';

import React from 'react';

export default ({ title, price, description }) => {
  return (
    <Option>
      <h2>{title}</h2>
      <span>R$ {price}</span>
      <p>{description}</p>
    </Option>
  );
};
