import { Wrap } from './styles';

import { ClicklavLogo } from 'components/Icons';
import React from 'react';
import { Link } from 'react-router-dom';
import ScrollableAnchor from 'react-scrollable-anchor';

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
      fill="white"
    />
  </svg>
);

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
      fill="white"
    />
  </svg>
);

const MailIcon = () => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6066 2.9812C29.6066 1.39995 28.3128 0.106201 26.7316 0.106201H3.73157C2.15032 0.106201 0.856567 1.39995 0.856567 2.9812V20.2312C0.856567 21.8125 2.15032 23.1062 3.73157 23.1062H26.7316C28.3128 23.1062 29.6066 21.8125 29.6066 20.2312V2.9812ZM26.7316 2.9812L15.2316 10.1687L3.73157 2.9812H26.7316ZM26.7316 20.2312H3.73157V5.8562L15.2316 13.0437L26.7316 5.8562V20.2312Z"
      fill="white"
    />
  </svg>
);

const EcoCarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M21.739 10.921c-1.347-.39-1.885-.538-3.552-.921 0 0-2.379-2.359-2.832-2.816-.568-.572-1.043-1.184-2.949-1.184h-7.894c-.511 0-.736.547-.07 1-.586.476-1.252 1.058-1.822 1.602 2.556.193 6.064 1.169 8.003 2.893-.452-.939-1.987-2.093-3.29-2.605 2.276-2.469 7.256.798 6.15 6.128-.191-.565-.552-1.031-1.125-1.372-1.538-.916-3.094-.241-4.513-1.189-.825-.553-1.134-1.42-1.048-2.246-1.492-.464-4.03-.662-5.4-.345-.916 1.034-1.397 1.929-1.397 3.416 0 1.76 1.042 3.718 3.174 3.718h.01c.413 1.162 1.512 2 2.816 2 1.304 0 2.403-.838 2.816-2h6.367c.413 1.162 1.512 2 2.816 2s2.403-.838 2.816-2h.685c1.994 0 2.5-1.776 2.5-3.165 0-2.041-1.123-2.584-2.261-2.914zm-15.739 6.279c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm12 0c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2z"
      fill="white"
    />
  </svg>
);

// const SendNowIcon = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//   >
//     <path
//       d="M23 0l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-2.789 3.857l.94.934c-.875.885-1.773 1.933-2.125 3.375l-1.286-.314c.434-1.774 1.491-3.004 2.471-3.995zm-1.565 7.642c-.715 0-1.353-.279-1.887-.83-1.458.515-2.844-.044-3.576-1.084-.575-.817-.703-1.853-.353-2.845-.552-.534-.83-1.166-.83-1.884 0-1.562 1.16-2.803 3.24-2.586l.195 1.117c-.664.062-1.277.097-1.674.494-.668.668-.467 2.063.787 2.433-.832.836-.751 2.037-.127 2.696.654.69 1.903.799 2.765-.059.385 1.305 1.798 1.422 2.433.787.392-.392.431-.995.492-1.649l1.125.229c.2 1.979-1.009 3.181-2.59 3.181zm-3.318-7.032l.314 1.287c1.755-.43 2.953-1.45 3.989-2.471l-.938-.931c-.876.866-1.927 1.764-3.365 2.115z"
//       fill="white"
//     />
//   </svg>
// );

export default () => (
  <ScrollableAnchor id="contato">
    <Wrap>
      <div className="container">
        <div>
          <h4>Contato</h4>
          <div>
            <a href="https://wa.me/5571986852056">
              <span>(71) 98685-2056</span> <WhatsAppIcon />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/clicklav_oficial">
              <span>@clicklav_oficial</span> <InstagramIcon />
            </a>
          </div>
          <div>
            <a href="mailto:comercial@clicklav.com.br?Subject=Contato%20ClickLav">
              <span>comercial@clicklav.com.br</span> <MailIcon />
            </a>
          </div>
          <div>
            <a href="mailto:adm@clicklav.com.br?Subject=Contato%20ClickLav">
              <span>adm@clicklav.com.br</span> <MailIcon />
            </a>
          </div>
        </div>
        <div>
          <Link to="/">
            <ClicklavLogo />
          </Link>
          <br /> <Link to="/">CLICKLAV</Link> &copy; 2020
        </div>
        <div>
          {/* <div>
            <Link to="contato">
              <button>
                <SendNowIcon />
                <br />
                <span>Envie uma mensagem rápida agora</span>
              </button>
            </Link>
          </div> */}
          <div>
            <Link to="simulador">
              <button>
                <EcoCarIcon />
                <br />
                <span>
                  Conheça mais de nossos serviços com o{' '}
                  <strong>simulador</strong>
                </span>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <Link to="/">CLICKLAV</Link> &copy; 2020 - 2024
        </div>
      </div>
    </Wrap>
  </ScrollableAnchor>
);
