import { RequestWrap, StepNavBt } from './styles';

import Animation from 'services/Animation';

import RequestSchema from './requestSchema';
import First from './views/First';
import Second from './views/Second';
import Third from './views/Third';

import { ArrowTitle } from '../HowItWorks';

import axios from 'axios';
import Button from 'components/Button';
import { format, add } from 'date-fns';
import { useFormik } from 'formik';
import React, { useReducer, useRef, useEffect, useCallback } from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

configureAnchors({ offset: -60, scrollDuration: 200 });

const initialState = {
  activeView: 0,
  lastView: 0,
  validView: [], // Lista de views que já foram validadas
  maxViews: 3,
  touchedViews: [],
};

const reducer = (state, { type, isValid, index }) => {
  let activeView, lastView, validView, touchedViews;
  switch (type) {
    case 'validateView':
      validView = [...state.validView];
      validView[index] = isValid;
      return { ...state, validView };
    case 'nextView':
      activeView = state.activeView;
      touchedViews = [...state.touchedViews];
      touchedViews[activeView] = true;
      activeView += 1;
      return { ...state, activeView, touchedViews };
    case 'prevView':
      activeView = state.activeView;
      touchedViews = [...state.touchedViews];
      touchedViews[activeView] = true;
      activeView -= 1;
      return { ...state, activeView, touchedViews };
    case 'moveTo':
      activeView = index - 1;
      lastView = state.activeView;
      touchedViews = [...state.touchedViews];
      touchedViews[lastView] = true;
      return { ...state, activeView, lastView, touchedViews };
    default:
      throw new Error();
  }
};

export default () => {
  const [viewsState, dispatch] = useReducer(reducer, initialState);
  const { activeView, touchedViews, validView } = viewsState;

  // Refs de cada step para poder trabalhar com a DOM, verificando alturas
  const flex = useRef(),
    firstStep = useRef(),
    secondStep = useRef(),
    thirdStep = useRef();

  const prevActiveView = useRef(0);

  const handleSubmit = (values, actions) => {
    const {
      name,
      email,
      phone,
      date,
      time,
      // lead,
      // covered_place,
      // place,
      latitude,
      longitude,
      address_1,
      address_2,
      city,
      state,
      // brand,
      model,
      // size,
      color,
      plate,
      product,
      // price,
      price_id,
    } = values;
    let car_model_id = parseInt(model),
      car_plate = plate,
      car_color_id = parseInt(color),
      product_id = parseInt(product),
      lat = parseFloat(latitude) || null,
      lng = parseFloat(longitude) || null;
    console.log(values, actions);
    const requestData = {
      query: `mutation { 
        request( 
          name: "${name}"
          email: "${email}"
          phone: "${phone}"
          address_1: "${address_1}"
          address_2: "${address_2}"
          city: "${city}"
          state: "${state}"
          lat: ${lat}
          lng: ${lng}
          car_model_id: ${car_model_id}
          car_color_id: ${car_color_id}
          car_plate: "${car_plate}"
          product_id: ${product_id}
          price_id: ${price_id}
          date: "${date}"
          time: "${time}"
        ){
          id
        }
      }`,
    };
    console.log(requestData);
    axios
      .post(`${process.env.REACT_APP_API_URL}`, requestData)
      .then(({ data: { data } }) => {
        console.log(data.colors);
        alert('Pedido concluído!');
        actions.resetForm();
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('[Axios Request Error] Data:', error.response.data);
          console.error('[Axios Request Error] Status:', error.response.status);
          console.log('[Axios Request Error] Headers:', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error('[Axios Request Error] Request:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('[Axios Request Error] Message:', error.message);
        }
        console.log(error.config);
        alert(
          'Ocorreu um erro ao enviar o pedido! Verifique se não há mau preenchimento de campos, tente novamente clicando no botão de "Solicitar Lavagens". Caso o problema persista entre em contato diretamente com a equipe de suporte +5571986056232.'
        );
      });
  };

  const formik = useFormik({
    initialValues: {
      date: format(new Date(), 'yyyy-MM-dd'),
      time: format(add(new Date(), { hours: 1 }), 'HH:mm'),
      lead: true,
      covered_place: false,
    },
    validationSchema: RequestSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const { values, touched, setFieldTouched, errors } = formik;

  /**
   * Ação de animação até o step ativo activeView
   */
  const scrollStep = useCallback(() => {
    let scrollLeft = flex.current.scrollLeft;
    let width = flex.current.offsetWidth;
    const animation = new Animation(
      500,
      stepNumber => {
        flex.current.scrollLeft =
          scrollLeft * (1 - stepNumber) + activeView * width * stepNumber;
      },
      () => {
        flex.current.scrollLeft = activeView * width;
      }
    );
    animation.start();
  }, [activeView]);

  // useEffect para validação de steps e alteração de estilo quando validado
  useEffect(() => {
    if (activeView !== prevActiveView.current) {
      // Valida a view prevActiveView
      const stepFields = [
        ['name', 'phone', 'email', 'place'],
        ['address_1', 'time', 'date', 'covered_place'],
        ['brand', 'model', 'color', 'plate', 'product'],
      ];
      let validSteps = [];
      // Armazenando dados em memória, o setstate do formik é assíncrono
      let memTouched = {};
      // tocando todos os inputs da view prévia para dar um trigger nos erros
      stepFields[prevActiveView.current].forEach(input => {
        console.log(
          'input espec',
          input,
          values[input],
          touched[input],
          errors[input]
        );
        if (!touched[input]) {
          setFieldTouched(input, true);
          memTouched[input] = true;
        }
      });

      stepFields.forEach((inputs, index) => {
        if (inputs) {
          validSteps[index] = true;
          inputs.forEach(input => {
            // caso ele tenha erro e touched ganha erro
            console.log(
              'input',
              input,
              values[input],
              touched[input] || memTouched[input],
              errors[input]
            );
            if ((touched[input] || memTouched[input]) && errors[input]) {
              validSteps[index] = false;
            } else if (!touched[input] && !memTouched[input]) {
              validSteps[index] = null;
            }
          });
        }
      });

      console.log(validSteps);
      validSteps.forEach((isValid, index) => {
        dispatch({
          type: 'validateView',
          isValid,
          index,
        });
      });

      // atribui aos previous state ref o valor da view atual
      console.log(activeView, prevActiveView.current);
      prevActiveView.current = activeView;
      console.log(activeView, prevActiveView.current);
    }
    console.log('lets test');
  }, [activeView, values, touched, errors, setFieldTouched, touchedViews]);

  const checkWrapSizes = useCallback(() => {
    const refs = {
      '0': firstStep,
      '1': secondStep,
      '2': thirdStep,
    };
    console.log(activeView, refs[activeView].current.offsetHeight);
    // Diz qual a nova altura do componente mãe
    flex.current.style.height = `${refs[activeView].current.offsetHeight}px`;
  }, [activeView]);

  useEffect(() => {
    if (typeof activeView !== 'undefined') {
      scrollStep();
      checkWrapSizes();
    }
  }, [activeView, scrollStep, checkWrapSizes]);

  return (
    <ScrollableAnchor id="solicitar-lavagem">
      <RequestWrap>
        <ArrowTitle>SOLICITAR LAVAGEM</ArrowTitle>
        <h3>Preencha o formulário abaixo</h3>

        <form key="request-form">
          <div className="tabs">
            <RequestFormNavBt
              step="1"
              title="Meus Dados"
              onClick={e =>
                dispatch({
                  type: 'moveTo',
                  index: 1,
                })
              }
              activeView={viewsState.activeView}
              hasError={validView[0] === false && touchedViews[0] === true}
              isValid={validView[0] === true && touchedViews[0] === true}
            />
            <RequestFormNavBt
              step="2"
              title="Onde e Quando"
              onClick={e =>
                dispatch({
                  type: 'moveTo',
                  index: 2,
                })
              }
              activeView={viewsState.activeView}
              hasError={validView[1] === false && touchedViews[1] === true}
              isValid={validView[1] === true && touchedViews[1] === true}
            />
            <RequestFormNavBt
              step="3"
              title="Meu Carro"
              onClick={e =>
                dispatch({
                  type: 'moveTo',
                  index: 3,
                })
              }
              activeView={viewsState.activeView}
              hasError={validView[2] === false && touchedViews[2] === true}
              isValid={validView[2] === true && touchedViews[2] === true}
            />
          </div>
          <div className="steps" ref={flex}>
            <First
              formikProps={formik}
              ref={firstStep}
              pseudoContext={viewsState}
              dispatch={dispatch}
            />
            <Second
              formikProps={formik}
              ref={secondStep}
              pseudoContext={viewsState}
              dispatch={dispatch}
              checkStepWrapSize={checkWrapSizes}
            />
            <Third
              formikProps={formik}
              ref={thirdStep}
              pseudoContext={viewsState}
              dispatch={dispatch}
            />
          </div>
          {/* <pre>{JSON.stringify(formik, null, 4)}</pre> */}
          {/* <pre className="right">{JSON.stringify(viewsState, null, 4)}</pre> */}
        </form>
        {!(formik.isValid === true && formik.dirty === true) && (
          <p className="small">
            Preencha todos os dados corretamente para liberar o botão abaixo:
          </p>
        )}
        <Button
          key="button-to-submit-outside-form"
          disabled={!(formik.isValid === true && formik.dirty === true)}
          onClick={formik.handleSubmit}
        >
          SOLICITAR LAVAGEM{' '}
          {formik.values.price && `R$ ${formik.values.price}.00`}
        </Button>
      </RequestWrap>
    </ScrollableAnchor>
  );
};

const RequestFormNavBt = ({
  step,
  title,
  onClick,
  activeView,
  hasError,
  isValid,
}) => {
  return (
    <StepNavBt
      className={`${activeView + 1 === parseInt(step) && 'active'} ${
        hasError && 'error'
      } ${isValid && 'valid'}`}
    >
      <button
        onClick={e => {
          e.preventDefault();
          onClick(step);
        }}
      >
        <span>{step}</span>
        <span>{title}</span>
      </button>
    </StepNavBt>
  );
};
