import * as Yup from 'yup';

const RequestSchema = Yup.object().shape(
  {
    name: Yup.string().required('Campo obrigatório!'),
    phone: Yup.string().required('Campo obrigatório'),
    email: Yup.string()
      .email('Precisa estar em formato de email')
      .required('Campo obrigatório'),
    place: Yup.number()
      .oneOf([1, 2, 3], 'Ainda não atendemos essa localidade.')
      .required('Campo obrigatório'),
    latitude: Yup.string().when('address_1', {
      is: address_1 => !!address_1,
      then: Yup.string(),
      otherwise: Yup.string().required('Campo obrigatório'),
    }),
    longitude: Yup.string().when('address_1', {
      is: address_1 => !!address_1,
      then: Yup.string(),
      otherwise: Yup.string().required('Campo obrigatório'),
    }),
    address_1: Yup.string().when('latitude', {
      is: latitude => !!latitude,
      then: Yup.string(),
      otherwise: Yup.string().required('Campo obrigatório'),
    }),
    city: Yup.string().when('latitude', {
      is: latitude => !!latitude,
      then: Yup.string(),
      otherwise: Yup.string().required('Campo obrigatório'),
    }),
    state: Yup.string().when('latitude', {
      is: latitude => !!latitude,
      then: Yup.string(),
      otherwise: Yup.string().required('Campo obrigatório'),
    }),
    date: Yup.string().required('Campo obrigatório'),
    time: Yup.string().required('Campo obrigatório'),
    covered_place: Yup.boolean()
      .required('É necessário que o carro esteja numa zona coberta')
      .oneOf([true], 'É necessário que o carro esteja numa zona coberta'),
    brand: Yup.string().required('Campo obrigatório'),
    model: Yup.string().required('Campo obrigatório'),
    color: Yup.string().required('Campo obrigatório'),
    plate: Yup.string().required('Campo obrigatório'),
    product: Yup.string().required('Campo obrigatório'),
  },
  [['latitude', 'address_1']]
);

export default RequestSchema;
