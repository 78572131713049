import { SelectWrap } from './styles';

import React, { useState, useEffect, useRef } from 'react';

export default ({
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  topRightCornerRadius,
  topLeftCornerRadius,
  bottomLeftCornerRadius,
  bottomRightCornerRadius,
  width,
  children,
  formikProps,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const input = useRef(null);

  useEffect(() => {
    if (input.current.value) setActive(true);
  }, []);

  const { errors, touched } = formikProps;

  return (
    <SelectWrap
      active={active}
      topLeftCornerRadius={topLeftCornerRadius}
      topRightCornerRadius={topRightCornerRadius}
      bottomLeftCornerRadius={bottomLeftCornerRadius}
      bottomRightCornerRadius={bottomRightCornerRadius}
      width={width}
    >
      <label htmlFor={name}>{label}</label>
      <select
        ref={input}
        id={name}
        onFocus={e => {
          setActive(true);
          if (onFocus) onFocus(e);
        }}
        onBlur={e => {
          console.log('blur');
          if (!e.target.value) setActive(false);
          if (onBlur) onBlur(e);
        }}
        onChange={e => {
          if (onChange) onChange(e);
        }}
        {...props}
      >
        {children}
      </select>
      {errors[name] && touched[name] && (
        <div className="errors">{errors[name]}</div>
      )}
    </SelectWrap>
  );
};
