import styled from 'styled-components';

export const Wrap = styled.section`
  width: 100%;
  background: #0e3182;
  color: #fff;
  padding-bottom: 30px;

  .container {
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 25px;

    font-family: Oxygen;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    > div {
      flex: 0 1 260px;

      @media screen and (max-width: 1060px) {
        margin: 20px auto;
        padding: 0 40px;
      }

      &:nth-child(1) {
        text-align: right;
        align-self: flex-end;

        @media screen and (max-width: 1060px) {
          text-align: left;
        }

        > div {
          display: inline-block;
        }

        h4 {
          font-family: Fugaz One;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        a {
          display: flex;
          flex-direction: row;
          line-height: 30px;

          @media screen and (max-width: 1060px) {
            flex-direction: row-reverse;
          }

          span {
            vertical-align: middle;
            display: inline-block;
          }

          svg {
            height: 16px;
            align-self: center;
          }
        }
      }

      &:nth-child(2) {
        text-align: center;
        align-self: flex-end;
        font-weight: bold;

        svg {
          margin-bottom: 15px;
          path {
            fill: white !important;
          }
        }

        @media screen and (max-width: 1060px) {
          display: none;
        }
      }

      &:nth-child(3) {
        button {
          padding: 10px 15px;
          color: ${({ theme }) => theme.blue};
          background: #fafafa;
          border: none;
          margin: 5px 0;
          cursor: pointer;
          text-align: center;
          border-radius: 10px;
          line-height: 14px;

          svg {
            display: inline-block;
            height: 30px;
            width: auto;
            path {
              fill: ${({ theme }) => theme.blue};
            }
          }

          &:hover {
            background: none;
            color: white;
            box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);

            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }

      &:nth-child(4) {
        text-align: center;
        align-self: flex-end;
        font-weight: bold;

        @media screen and (min-width: 1060px) {
          display: none;
        }
      }

      a {
        text-decoration: none;
        color: #fff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
