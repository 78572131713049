import { Wrap, H2, Table } from './styles';

import React, { useState, useEffect } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import VisibilitySensor from 'react-visibility-sensor';

const LeftArrow = () => (
  <svg
    width="88"
    height="28"
    viewBox="0 0 88 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.7105 16.5809L87.6785 27.4489L86.3785 20.1689L74.9385 13.9289L84.1945 7.58493L82.8945 0.304932L66.7745 11.2249L67.7105 16.5809Z"
      fill="#7AC943"
    />
    <path
      d="M45.773 16.5809L65.741 27.4489L64.441 20.1689L53.001 13.9289L62.257 7.58493L60.957 0.304932L44.837 11.2249L45.773 16.5809Z"
      fill="#7AC943"
    />
    <path
      d="M23.8355 16.5809L43.8035 27.4489L42.5035 20.1689L31.0635 13.9289L40.3195 7.58493L39.0195 0.304932L22.8995 11.2249L23.8355 16.5809Z"
      fill="#7AC943"
    />
    <path
      d="M1.89796 16.5809L21.866 27.4489L20.566 20.1689L9.12596 13.9289L18.382 7.58493L17.082 0.304932L0.96196 11.2249L1.89796 16.5809Z"
      fill="#7AC943"
    />
  </svg>
);

const RightArrow = () => (
  <svg
    width="87"
    height="28"
    viewBox="0 0 87 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0029 16.5809L0.0349121 27.4489L1.33491 20.1689L12.7749 13.9289L3.51891 7.58493L4.81891 0.304932L20.9389 11.2249L20.0029 16.5809Z"
      fill="#7AC943"
    />
    <path
      d="M41.9404 16.5809L21.9724 27.4489L23.2724 20.1689L34.7124 13.9289L25.4564 7.58493L26.7564 0.304932L42.8764 11.2249L41.9404 16.5809Z"
      fill="#7AC943"
    />
    <path
      d="M63.8779 16.5809L43.9099 27.4489L45.2099 20.1689L56.6499 13.9289L47.3939 7.58493L48.6939 0.304932L64.8139 11.2249L63.8779 16.5809Z"
      fill="#7AC943"
    />
    <path
      d="M85.8154 16.5809L65.8474 27.4489L67.1474 20.1689L78.5874 13.9289L69.3314 7.58493L70.6314 0.304932L86.7514 11.2249L85.8154 16.5809Z"
      fill="#7AC943"
    />
  </svg>
);

const Step = ({ number, children, showIcon }) => (
  <div className="col step">
    <div className={`numberIcon ${showIcon && 'show'}`}>{number}</div>
    <p>{children}</p>
  </div>
);

export const ArrowTitle = ({ children }) => (
  <H2>
    <LeftArrow />
    {children}
    <RightArrow />
  </H2>
);

export default props => {
  const [visibility, setVisibility] = useState(false);

  const toogleVisibility = isVisible => {
    console.log('visibilidade', visibility, isVisible);
    if (!visibility && isVisible) {
      setVisibility(true);
    }
  };

  useEffect(() => {
    console.log('visibilidade', visibility);
  }, [visibility]);

  return (
    <ScrollableAnchor id="como-funciona">
      <Wrap>
        <ArrowTitle>COMO FUNCIONA</ArrowTitle>
        <VisibilitySensor partialVisibility={true} onChange={toogleVisibility}>
          <Table visibility={visibility}>
            <div className="row">
              <Step number={1}>
                Baixe o nosso aplicativo, cadastre-se, cadastre seu carro e faça
                o pedido do serviço de estética automotiva que você deseja.
              </Step>
              <Step number={2}>
                Nosso parceiro Clicklav vai até o endereço conforme agendamento.
              </Step>
              <Step number={3}>
                Nosso parceiro Clicklav efetua a lavagem do veículo.
              </Step>
            </div>
            <div className="row">
              <Step number={4}>
                E pronto, seu carro está limpo!!! Confira o serviço e efetue o
                pagamento diretamente com o nosso parceiro!!! (Dinheiro, Débito
                ou Crédito).
              </Step>
              <Step number={5}>
                Após a lavagem é feito contato para avaliação dos serviços
                prestados.
              </Step>
            </div>
            <div className="row">
              <div className="col">
                OBS: Não usamos água nem ponto de energia na residência onde
                será prestado o serviço
              </div>
            </div>
          </Table>
        </VisibilitySensor>
      </Wrap>
    </ScrollableAnchor>
  );
};
