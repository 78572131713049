import { Input } from '../TextField/styles';

import styled from 'styled-components';

export const SelectWrap = styled(Input)`
  select {
    height: 65px;
    padding: 21px 19px 21px 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    font-family: Oxygen;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    outline: 0;
    border-top-left-radius: ${({ topLeftCornerRadius }) =>
      topLeftCornerRadius ? '8px' : '0'};
    border-top-right-radius: ${({ topRightCornerRadius }) =>
      topRightCornerRadius ? '8px' : '0'};
    border-bottom-left-radius: ${({ bottomLeftCornerRadius }) =>
      bottomLeftCornerRadius ? '8px' : '0'};
    border-bottom-right-radius: ${({ bottomRightCornerRadius }) =>
      bottomRightCornerRadius ? '8px' : '0'};
    width: 100%;
  }
`;
