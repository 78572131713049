import React from 'react';

export const ClicklavLogo = () => (
  <svg
    className="logo"
    width="110"
    height="95"
    viewBox="0 0 110 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1744 85.7605C11.7904 84.2671 10.8186 83.1735 8.92516 83.1735C6.122 83.1735 4.95229 85.6341 4.95229 88.1299C4.95229 90.6257 6.13381 93.0863 8.92516 93.0863C10.9574 93.0863 12.0739 91.5723 12.2571 89.6791H14.4901C14.307 92.7923 12.0916 94.9442 8.92516 94.9442C5.00546 94.9442 2.66309 91.8457 2.66309 88.1299C2.66309 84.4141 4.9966 81.3097 8.9163 81.3097C11.8701 81.3273 14.1888 82.9471 14.4636 85.7546L12.1744 85.7605Z"
      fill="#47C8EE"
    />
    <path
      d="M18.2238 81.6243H20.51V92.6659H24.5154V94.6356H18.2238V81.6243Z"
      fill="#47C8EE"
    />
    <path
      d="M27.4869 81.6243H29.7791V94.6356H27.4899L27.4869 81.6243Z"
      fill="#47C8EE"
    />
    <path
      d="M42.5188 85.7605C42.1348 84.2671 41.166 83.1735 39.2696 83.1735C36.4694 83.1735 35.2968 85.6341 35.2968 88.1299C35.2968 90.6257 36.4783 93.0863 39.2696 93.0863C41.3019 93.0863 42.4184 91.5723 42.6015 89.6791H44.8346C44.6515 92.7923 42.4361 94.9442 39.2696 94.9442C35.3529 94.9442 33.0076 91.8457 33.0076 88.1299C33.0076 84.4141 35.344 81.3097 39.2608 81.3097C42.2146 81.3273 44.5333 82.9471 44.808 85.7546L42.5188 85.7605Z"
      fill="#47C8EE"
    />
    <path
      d="M50.3198 81.6243H48.0306V94.6356H50.3198V81.6243Z"
      fill="#47C8EE"
    />
    <path
      d="M54.9898 88.0064L60.1855 81.6243H57.3646L52.3845 88.0006L57.6955 94.6356H60.5518L54.9898 88.0064Z"
      fill="#47C8EE"
    />
    <path
      d="M51.4304 1.70734L48.964 4.11792C48.964 7.1488 48.9138 18.0435 48.8606 24.264C48.8311 28.0386 47.0499 29.7319 43.2484 29.8701C36.0411 30.1258 28.8604 29.5291 21.6708 29.2468C19.4702 29.1587 18.9415 28.0533 19.6356 26.1631C20.6163 23.485 21.8008 20.8715 22.6278 18.1523C23.5908 14.9803 25.7677 13.5663 28.8633 13.2282C31.6281 12.9342 44.4831 11.5819 47.2508 11.3085L47.3749 6.40211C44.87 6.54321 32.2927 7.87198 29.7938 8.08364C23.9187 8.55694 18.9444 10.1679 16.9831 16.6412C16.3838 18.4802 15.6462 20.2717 14.7766 22.0004L13.4031 20.8392L7.46594 21.5888C7.21582 21.6184 6.98176 21.7268 6.7979 21.8982C6.61404 22.0695 6.49002 22.2948 6.44392 22.5413L6.22534 23.7583C6.17682 24.0211 6.23078 24.2923 6.37622 24.5168C6.52166 24.7413 6.74764 24.9021 7.0081 24.9666L12.2866 26.2865C10.9544 27.3977 8.41411 29.1704 6.91949 30.1699C5.04087 31.4281 3.91251 33.2008 3.10612 35.2763C1.51106 39.3919 1.28953 43.7221 1.09458 48.0112C0.680601 56.0027 1.03073 64.0152 2.14023 71.9407C2.41198 73.7987 3.24495 75.0216 5.29194 75.0245C11.3679 75.0245 15.8784 75.0245 21.9544 75.0245C23.1536 75.0245 24.3942 74.6512 24.6335 73.4929C25.6703 68.4954 25.9213 65.879 28.8751 65.6056C29.2503 65.5732 39.1633 65.4557 48.5091 65.3704C49.3432 65.3634 50.1406 65.0279 50.7268 64.4374C51.313 63.8468 51.6403 63.0492 51.6372 62.219L51.4304 1.70734ZM12.9246 41.8672C11.8671 40.8236 11.1228 39.2802 10.4286 37.0136L11.235 35.9054L24.9614 38.9509C25.1214 39.0487 25.2762 39.1546 25.4251 39.2684L28.2755 42.7785L27.6848 44.1014L12.9246 41.8672Z"
      fill="white"
    />
    <path
      d="M58.883 62.219C58.8798 63.0486 59.2067 63.8458 59.7923 64.4363C60.3779 65.0268 61.1745 65.3626 62.0081 65.3704C71.3539 65.4556 81.2669 65.5732 81.642 65.6055C84.5958 65.8789 84.8469 68.4836 85.8837 73.4929C86.123 74.6511 87.3606 75.0245 88.5628 75.0245C94.6388 75.0245 99.1493 75.0245 105.225 75.0245C107.272 75.0245 108.105 73.7986 108.377 71.9407C109.486 64.0152 109.837 56.0026 109.423 48.0112C109.228 43.7221 109.003 39.383 107.411 35.2762C106.605 33.2007 105.476 31.4281 103.598 30.1699C102.103 29.1704 99.5628 27.3977 98.2306 26.2865L103.509 24.9665C103.769 24.9015 103.995 24.7405 104.14 24.5161C104.285 24.2918 104.34 24.0209 104.292 23.7583L104.073 22.5412C104.028 22.2965 103.906 22.0724 103.724 21.9013C103.542 21.7301 103.311 21.6207 103.063 21.5888L97.1259 20.8391L95.7524 22.0003C94.8825 20.2697 94.1449 18.4763 93.5459 16.6353C91.5846 10.1678 86.6103 8.55099 80.7352 8.07769C78.2363 7.87779 65.656 6.53727 63.1542 6.39616L63.2782 11.3026C66.0459 11.576 78.9009 12.9165 81.6657 13.2222C84.7613 13.5603 86.9382 14.9861 87.9011 18.1463C88.7253 20.8685 89.9097 23.479 90.8934 26.1571C91.5875 28.0474 91.0588 29.1527 88.8582 29.2409C81.6657 29.5349 74.485 30.1228 67.2806 29.8641C63.4673 29.726 61.6861 28.0327 61.6684 24.258C61.6152 18.0375 61.565 7.14285 61.565 4.11198L59.0986 1.70139L58.883 62.219ZM82.8088 44.1013L82.218 42.7784L85.0891 39.2713C85.2427 39.1567 85.3845 39.0508 85.5558 38.9538L99.2822 35.9082L100.089 37.0165C99.3944 39.2831 98.6501 40.8382 97.5926 41.87L82.8088 44.1013Z"
      fill="white"
    />
    <path
      d="M53.0255 0.822449H57.6275V64.5296C57.6275 64.7378 57.5444 64.9374 57.3965 65.0846C57.2486 65.2318 57.048 65.3145 56.8389 65.3145H53.8142C53.605 65.3145 53.4044 65.2318 53.2565 65.0846C53.1086 64.9374 53.0255 64.7378 53.0255 64.5296V0.822449Z"
      fill="#7AC943"
    />
    <path
      d="M98.2542 81.7564L94.2606 91.6046L90.2405 81.7182H89.2599V81.6829H83.748L84.516 83.7819H87.91C88.0119 83.7819 88.1117 83.8117 88.1967 83.8676C88.2818 83.9235 88.3485 84.0031 88.3885 84.0965L92.8192 94.6296H104.085L106.943 88.1533L109.801 81.6771L98.2542 81.7564Z"
      fill="#7AC943"
    />
    <path
      d="M87.2218 92.5248H84.2444L79.536 81.7183H76.9485L72.4616 92.2396C72.4222 92.3327 72.356 92.4122 72.2714 92.4682C72.1869 92.5241 72.0876 92.554 71.9861 92.5542H65.0653V81.7183H62.6609V94.6296H73.5634C73.6655 94.63 73.7653 94.6 73.8501 94.5434C73.9348 94.4868 74.0006 94.4063 74.039 94.3121L78.1743 84.1818L80.2154 89.0323H77.9527C77.8497 89.0326 77.7491 89.0636 77.6638 89.1212C77.5785 89.1788 77.5125 89.2605 77.4742 89.3557L76.7771 91.1196H81.0867L82.5489 94.6032V94.6355H82.5636H85.1482H87.978L87.2218 92.5248Z"
      fill="#7AC943"
    />
  </svg>
);
