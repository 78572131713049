import Wrap, { MapWrap } from './styles';

import axios from 'axios';
import Button from 'components/Button';
import TextField from 'components/TextField';
import L from 'leaflet';
import React, { Component, createRef, useState, forwardRef } from 'react';
import { useEffect } from 'react';

class LeafletMap extends Component {
  mymap = createRef();
  map = null;
  marker = null;
  lat = -12.89771;
  long = -38.32549;

  componentDidMount() {
    const { coords } = this.props;
    this.map = L.map(this.mymap.current, {
      minZoom: 10,
      maxZoom: 30,
      zoom: 20,
    }).setView([coords.x || this.lat, coords.y || this.long], 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.marker = L.marker([coords.x || this.lat, coords.y || this.long]).addTo(
      this.map
    );
    // .bindPopup("Você está aqui.")
    // .openPopup();

    this.map.on('moveend', () => {
      const actualCoords = this.map.getCenter();
      const { lat, lng } = actualCoords;
      console.log(this.map.getCenter());
      if (this.marker) this.map.removeLayer(this.marker);
      this.marker = L.marker([lat || this.lat, lng || this.long]).addTo(
        this.map
      );
      axios(
        `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lng}&format=json`
      ).then(response => {
        console.log(response);
        this.props.onMapChange(response.data);
      });
    });

    this.map.on('click', e => {
      const actualCoords = e.latlng;
      const { lat, lng } = actualCoords;
      console.log(this.map.getCenter());
      if (this.marker) this.map.removeLayer(this.marker);
      this.marker = L.marker([lat || this.lat, lng || this.long]).addTo(
        this.map
      );
      axios(
        `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lng}&format=json`
      ).then(response => {
        console.log(response);
        this.props.onMapChange(response.data);
      });
    });
  }

  shouldComponentUpdate(nextProps) {
    const { coords } = this.props;
    if (coords.x !== nextProps.coords.x || coords.y !== nextProps.coords.y)
      return true;
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { coords } = this.props;
    console.log('atualizar');
    this.map.panTo([coords.x || this.lat, coords.y || this.long], {
      animate: true,
      duration: 0.6,
    });
    if (coords.x !== prevProps.coords.x || coords.y !== prevProps.coords.y) {
      console.log('props', this.props, prevProps);
      console.log('state', this.state, prevState);
      console.log('map', this.map, coords);
      if (this.marker) this.map.removeLayer(this.marker);
      this.marker = L.marker([
        coords.x || this.lat,
        coords.y || this.long,
      ]).addTo(this.map);
    }
  }

  render() {
    return (
      <MapWrap>
        <div ref={this.mymap} />
      </MapWrap>
    );
  }
}

export default forwardRef(
  (
    { formikProps, checkStepWrapSize, pseudoContext: context, dispatch },
    ref
  ) => {
    const [x, setX] = useState(null);
    const [y, setY] = useState(null);
    // se null, deve mostrar as opções, se false, deve motrar a localização, se trur, os campos para ser digitado manualmente
    const [getByAddress, setGetByAddress] = useState(null);

    // check for Geolocation support
    const getLocation = e => {
      e.preventDefault();

      const geoSuccess = function (position) {
        // Do magic with location
        const startPos = position;
        console.log(startPos.coords.longitude, startPos.coords.latitude);
        // Fazendo que as posições sejam sempre diferentes para que seja atualizado sempre
        let random = 1 + Math.random() * 0.000001;
        setX(startPos.coords.latitude * random);
        setY(startPos.coords.longitude * random);
      };

      const geoError = function (error) {
        switch (error.code) {
          case error.TIMEOUT:
            // The user didn't accept the callout
            console.log('deu bronca');
            break;
          default:
            console.log('Algum erro deu');
        }
      };

      if (navigator.geolocation) {
        console.log('Geolocation is supported!');
        navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
      } else {
        console.log('Geolocation is not supported for this Browser/OS.');
      }
    };

    const getCEP = e => {
      let cep = e.target.value.replace(/\D/g, '');
      if (cep !== '') {
        const validaCEP = /^[0-9]{8}$/;
        if (validaCEP.test(cep)) {
          e.currentTarget.blur();
          axios.get(`https://viacep.com.br/ws/${cep}/json`).then(response => {
            const { logradouro, bairro, localidade, uf } = response.data;
            formikProps.setFieldValue('address_1', `${logradouro} - ${bairro}`);
            formikProps.setFieldValue('address_2', bairro);
            formikProps.setFieldValue('city', localidade);
            formikProps.setFieldValue('state', uf);
          });
        }
      }
    };

    const changePlace = data => {
      if (data && data.address) {
        console.log('changePlaceData', data);
        formikProps.setFieldValue('latitude', data.lat);
        formikProps.setFieldValue('longitude', data.lon);
        formikProps.setFieldValue('address_1', data.address.road);
        formikProps.setFieldValue(
          'address_2',
          data.address.neighbourhood || data.address.suburb
        );
        formikProps.setFieldValue('city', data.address.city);
        formikProps.setFieldValue('state', data.address.state);
      }
    };

    useEffect(() => {
      checkStepWrapSize();
    }, [getByAddress, checkStepWrapSize]);

    const GpsBt = () => (
      <button
        type="button"
        onClick={e => {
          setGetByAddress(false);
          getLocation(e);
        }}
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9399 11C20.48 6.83002 17.17 3.52002 13 3.06V1H11V3.06C6.82996 3.52002 3.52002 6.83002 3.06006 11H1V13H3.06006C3.52002 17.17 6.82996 20.48 11 20.94V23H13V20.94C17.17 20.48 20.48 17.17 20.9399 13H23V11H20.9399ZM12 8C9.79004 8 8 9.78998 8 12C8 14.21 9.79004 16 12 16C14.21 16 16 14.21 16 12C16 9.78998 14.21 8 12 8ZM5 12C5 15.87 8.13 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5C8.13 5 5 8.13 5 12Z"
          />
        </svg>
        Obter localização por GPS do local atual
      </button>
    );

    const AddressBt = () => (
      <button type="button" onClick={() => setGetByAddress(true)}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 12.5H2L12 3.5L22 12.5H19V20.5H13V14.5H11V20.5H5V12.5ZM17 10.69L12 6.19L7 10.69V18.5H9V12.5H15V18.5H17V10.69Z"
          />
        </svg>
        Digitar a localização de forma manual
      </button>
    );

    return (
      <Wrap className="step step-2" ref={ref}>
        {/* {formikProps.values.latitude && (
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${formikProps.values.latitude},${formikProps.values.longitude}&travelmode=driving`}
          >
            {`https://www.google.com/maps/dir/?api=1&destination=${formikProps.values.latitude},${formikProps.values.longitude}&travelmode=driving`}
          </a>
        )} */}
        {getByAddress === null ? (
          <div id="select_address_method">
            <GpsBt />
            <AddressBt />
          </div>
        ) : (
          <>
            <div id="change_address_method">
              {getByAddress === false ? <AddressBt /> : <GpsBt />}
            </div>
            {getByAddress === false && (
              <>
                <p>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 2C6.47998 2 2 6.48001 2 12C2 17.52 6.47998 22 12 22C17.52 22 22 17.52 22 12C22 6.48001 17.52 2 12 2ZM11 17V15H13V17H11ZM11 7V13H13V7H11Z"
                      fill="black"
                    />
                  </svg>
                  Se você não estiver com o GPS habilitado é possível que o
                  local no mapa seja um local próximo de onde você está, arraste
                  o mapa e centralize a rua onde você se encontra.
                </p>
                <LeafletMap coords={{ x, y }} onMapChange={changePlace} />
              </>
            )}
            <TextField
              label="CEP"
              type={getByAddress === false ? 'hidden' : 'text'}
              onChange={e => {
                formikProps.handleChange(e);
                getCEP(e);
              }}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.cep}
              name="cep"
              width={100}
              formikProps={formikProps}
              disabled={getByAddress === false}
              topLeftCornerRadius
              topRightCornerRadius
            />
            <TextField
              label="Endereço Completo*"
              type={getByAddress === false ? 'hidden' : 'text'}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.address_1}
              name="address_1"
              width={100}
              formikProps={formikProps}
              forcedFocus={formikProps.values.address_1}
              disabled={getByAddress === false}
            />
            <TextField
              label="Endereço 2"
              type={getByAddress === false ? 'hidden' : 'text'}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.address_2}
              name="address_2"
              width={100}
              formikProps={formikProps}
              forcedFocus={formikProps.values.address_2}
              disabled={getByAddress === false}
            />
            <TextField
              label="Cidade"
              type={getByAddress === false ? 'hidden' : 'text'}
              value={formikProps.values.city}
              name="city"
              width={50}
              formikProps={formikProps}
              forcedFocus={formikProps.values.city}
              disabled={getByAddress === false}
            />
            <TextField
              label="Estado"
              type={getByAddress === false ? 'hidden' : 'text'}
              value={formikProps.values.state}
              name="state"
              width={50}
              formikProps={formikProps}
              forcedFocus={formikProps.values.state}
              disabled={getByAddress === false}
            />
            <TextField
              label="Data *"
              type="date"
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.date}
              name="date"
              width={50}
              formikProps={formikProps}
              bottomLeftCornerRadius
              icon={
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.85669 9H6.85669V11H4.85669V9ZM4.85669 13H6.85669V15H4.85669V13ZM8.85669 9H10.8567V11H8.85669V9ZM8.85669 13H10.8567V15H8.85669V13ZM12.8567 9H14.8567V11H12.8567V9ZM12.8567 13H14.8567V15H12.8567V13Z"
                    fill="#0E3182"
                  />
                  <path
                    d="M2.85669 20H16.8567C17.9597 20 18.8567 19.103 18.8567 18V4C18.8567 2.897 17.9597 2 16.8567 2H14.8567V0H12.8567V2H6.85669V0H4.85669V2H2.85669C1.75369 2 0.856689 2.897 0.856689 4V18C0.856689 19.103 1.75369 20 2.85669 20ZM16.8567 6L16.8577 18H2.85669V6H16.8567Z"
                    fill="#0E3182"
                  />
                </svg>
              }
            />
            <TextField
              label="Horário *"
              type="time"
              step="3600"
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.time}
              name="time"
              width={50}
              formikProps={formikProps}
              bottomRightCornerRadius
              icon={
                <svg
                  width="17"
                  height="24"
                  viewBox="0 0 17 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.85669 19C12.7227 19 15.8567 15.866 15.8567 12C15.8567 8.13401 12.7227 5 8.85669 5C4.9907 5 1.85669 8.13401 1.85669 12C1.85669 15.866 4.9907 19 8.85669 19Z"
                    stroke="#0E3182"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.85669 9V12L10.3567 13.5"
                    stroke="#0E3182"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.34669 6.65002L4.69669 2.82002C4.7416 2.32309 4.97062 1.8609 5.33881 1.52417C5.70699 1.18744 6.18775 1.00049 6.68669 1.00002H11.0367C11.5374 0.997985 12.0206 1.18381 12.3909 1.52079C12.7612 1.85776 12.9916 2.32137 13.0367 2.82002L13.3867 6.65002M13.3667 17.35L13.0167 21.18C12.9716 21.6787 12.7412 22.1423 12.3709 22.4792C12.0006 22.8162 11.5174 23.002 11.0167 23H6.68669C6.18602 23.002 5.7028 22.8162 5.3325 22.4792C4.96219 22.1423 4.73175 21.6787 4.68669 21.18L4.33669 17.35H13.3667Z"
                    stroke="#0E3182"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
            <input
              type="checkbox"
              name="covered_place"
              id="covered_place"
              onChange={e => {
                formikProps.setFieldTouched('covered_place', true);
                formikProps.setFieldValue(
                  'covered_place',
                  e.target.checked,
                  true
                );
              }}
              value={formikProps.values.covered_place}
            />
            <label htmlFor="covered_place">
              Estou ciente que o carro deve estar em um local seguro e coberto
            </label>
            {formikProps.errors.covered_place && (
              <span className="errors">{formikProps.errors.covered_place}</span>
            )}
            <br />
          </>
        )}
        <Button
          type="button"
          className="inline no-padding"
          onClick={() =>
            dispatch({
              type: 'prevView',
            })
          }
        >
          Voltar
        </Button>
        <Button
          type="button"
          className="inline no-padding"
          onClick={() =>
            dispatch({
              type: 'nextView',
            })
          }
        >
          Próximo
        </Button>
      </Wrap>
    );
  }
);
