import { Wrap, H2 } from './styles';

import AppLogo from './appLogo';

import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

export default props => {
  return (
    <ScrollableAnchor id="app">
      <Wrap>
        <div className="container">
          <div>
            <AppLogo />
            <H2>BAIXE NOSSO APP</H2>
            <div>
              <p>
                Nosso aplicativo chegou! E com ele mais praticidade, baixe agora
                e mude sua forma de lavar o carro: com um click!
              </p>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.clicklav.app">
                  <button>
                    <img
                      alt="Disponível no Google Play Store"
                      src="assets/img/google-play-badge.png"
                    ></img>
                  </button>
                </a>
                <a href="#leads">
                  <button className="upcoming">
                    <img
                      alt="Em breve na Apple Store"
                      src="assets/img/download-on-the-app-store.svg"
                    />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Wrap>
    </ScrollableAnchor>
  );
};
