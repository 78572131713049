import ModalWrap, { Courtain } from './styles';

import React from 'react';

export default ({ children, open, handleClose }) => {
  return [
    open && <Courtain open={open} onClick={handleClose} key="courtain" />,
    open && (
      <ModalWrap key="modal" open={open}>
        <div className="content">{children}</div>
      </ModalWrap>
    ),
  ];
};
