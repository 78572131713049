import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Contact from 'views/Contact';
import Index from 'views/Index';
import Simulator from 'views/Simulator';
import Terms from 'views/Terms';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Index} />
    <Route exact path="/simulador" component={Simulator} />
    <Route exact path="/contato" component={Contact} />
    <Route exact path="/termos-de-uso" component={Terms} />
  </Switch>
);

export default Routes;
