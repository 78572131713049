import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    font-family: 'Oxygen', sans-serif;
    /* font-family: 'Fugaz One', cursive; */
  }

  html, body, #root{
    height: 100%;
    background: ${props => props.theme.white};
  }

  h1{
    color: ${props => props.theme.black};
  }
`;
