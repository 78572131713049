import Wrap from './styles';

import axios from 'axios';
import Button from 'components/Button';
import PopupSelectField from 'components/PopupSelectField';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';
import WashOption from 'components/WashOption';
import React, { useEffect, useState, forwardRef } from 'react';

export default forwardRef(({ formikProps, dispatch }, ref) => {
  const [brands, setBrands] = useState(null);
  const [models, setModels] = useState(null);
  const [colors, setColors] = useState(null);
  const [size, setSize] = useState(null);
  const [products, setProducts] = useState(null);
  const [priceCatalog, setPriceCatalog] = useState(null);
  const { values, setFieldValue } = formikProps;

  const sort = (a, b, prop) => {
    // a significa um item, b o seguinte
    // se um título está com a letra anterior da ordem alfabética do que a sua posterior comparada ele deve vir primeiro, por isso retorna -1
    if (a[prop] < b[prop]) {
      return -1;
    }
    // Se o título de a é maior que o de b, ou seja, vem depois no alfabeto, a deve ficar depois de b, por isso retorna 1
    if (a[prop] > b[prop]) {
      return 1;
    }
    // Se forem iguais, tanto faz
    return 0;
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}`, {
        query:
          '{ carBrands{ id, name } colors{ id, name, hex } products{ id, name } }',
      })
      .then(({ data: { data } }) => {
        setBrands(data.carBrands.sort((a, b) => sort(a, b, 'name')));
        setColors(data.colors.sort((a, b) => sort(a, b, 'name')));
        setProducts(data.products.sort((a, b) => sort(a, b, 'name')));
      })
      .catch(err => console.log(err));
  }, []);

  const loadModels = e => {
    formikProps.setFieldValue('model', '');
    formikProps.setFieldValue('size', null);

    const brandId = e.target.value;
    axios
      .post(`${process.env.REACT_APP_API_URL}`, {
        query: `{ carModels(brandId:${brandId}){ id, name, size{ id, alias } } }`,
      })
      .then(({ data: { data } }) =>
        setModels(data.carModels.sort((a, b) => sort(a, b, 'name')))
      )
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (values.model) {
      const carModel = models.filter(model => {
        return model.id === parseInt(values.model);
      });
      console.log('models', carModel);
      setFieldValue('size', carModel[0].size.id);
      setSize(carModel[0].size);
    }
  }, [values.model, values.size, models, setFieldValue]);

  useEffect(() => {
    if (values.size && values.product) {
      axios
        .post(`${process.env.REACT_APP_API_URL}`, {
          query: `{ price(car_size:${values.size}, product:${values.product}){ price, id } }`,
        })
        .then(({ data: { data } }) => {
          setFieldValue('price', data.price.price);
          setFieldValue('price_id', data.price.id);
        })
        .catch(err => console.log(err));
    }
  }, [values.size, values.product, setFieldValue]);

  useEffect(() => {
    if (values.size) {
      axios
        .post(`${process.env.REACT_APP_API_URL}`, {
          query: `{
            priceCatalog(carSizeId: ${values.size}) {
              price
              carSize {
                alias
              }
              product {
                id
                name
                description
              }
            }
          }`,
        })
        .then(({ data: { data } }) => {
          setPriceCatalog(data.priceCatalog);
          console.log(data);
        })
        .catch(err => console.log(err));
    }
  }, [values.size]);

  return (
    <Wrap className="step step-3" ref={ref}>
      {size && size.alias && <div className="car_size">{size.alias}</div>}
      <SelectField
        label={brands ? 'Marca do carro *' : 'Carregando...'}
        onChange={e => {
          formikProps.handleChange(e);
          loadModels(e);
        }}
        onBlur={formikProps.handleBlur}
        value={formikProps.values.brand}
        name="brand"
        formikProps={formikProps}
        width={50}
        topLeftCornerRadius
        disabled={!brands}
      >
        <option value="" />
        {brands &&
          brands.map(({ id, brand }) => (
            <option key={id} value={id}>
              {brand}
            </option>
          ))}
      </SelectField>
      <SelectField
        label="Modelo do carro *"
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        value={formikProps.values.model}
        name="model"
        formikProps={formikProps}
        topRightCornerRadius
        width={50}
        disabled={!models}
      >
        <option value="" />
        {models &&
          models.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
      </SelectField>
      <SelectField
        label={colors ? 'Cor do carro *' : 'Carregando...'}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        value={formikProps.values.color}
        name="color"
        formikProps={formikProps}
        width={50}
        disabled={!colors}
      >
        <option value="" />
        {colors &&
          colors.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
      </SelectField>
      <TextField
        label="Placa do carro *"
        type="text"
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        value={formikProps.values.plate}
        name="plate"
        width={50}
        formikProps={formikProps}
      />
      <PopupSelectField
        label={products ? 'Tipo de lavagem *' : 'Carregando...'}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        value={formikProps.values.product}
        name="product"
        formikProps={formikProps}
        width={100}
        bottomLeftCornerRadius
        bottomRightCornerRadius
        options={
          priceCatalog
            ? priceCatalog.reduce((acc, cur) => {
                return [
                  ...acc,
                  {
                    id: cur.product.id,
                    value: cur.product.id,
                    render: (
                      <WashOption
                        title={cur.product.name}
                        price={parseFloat(cur.price).toFixed(2)}
                        description={cur.product.description}
                      />
                    ),
                    label: cur.product.name,
                  },
                ];
              }, [])
            : []
        }
        onFail={() =>
          alert('É necessário preencher antes a Marca e Modelo do carro')
        }
      />
      <Button
        type="button"
        className="inline no-padding"
        onClick={() =>
          dispatch({
            type: 'prevView',
          })
        }
      >
        Voltar
      </Button>
    </Wrap>
  );
});
