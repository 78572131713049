import styled from 'styled-components';

export const SlideWrap = styled.section`
  position: relative;
  width: 100%;
  height: 510px;

  @media screen and (min-width: 375px) {
    background-image: url('/assets/img/banner_1.png');
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 375px) {
    background-image: url('/assets/img/banner_mobile.jpg');
    background-size: contain;
    background-position: center;
    background-color: #014195;
    background-repeat: no-repeat;

    img {
      display: none;
    }

    button {
      white-space: nowrap;
      position: absolute;
      bottom: 40px;
      left: -2%;
    }
  }

  img {
    animation: fadeInLeft 1s linear;
    width: 45vw;
    padding-top: 3%;

    @media screen and (max-width: 2024px) {
      padding-top: 2%;
    }

    @media screen and (max-width: 1880px) {
      padding-top: 4%;
    }

    @media screen and (max-width: 1440px) {
      padding-top: 8%;
    }

    @media screen and (max-width: 1100px) {
      padding-top: 11%;
    }

    @media screen and (max-width: 630px) {
      width: 80vw;
      padding-top: 20%;
    }

    @keyframes fadeInLeft {
      from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  button {
    margin-left: 13vw;
    margin-top: 20px;

    @media screen and (max-width: 630px) {
      margin-top: 40px;
      margin-left: 23vw;
    }
  }

  span {
    opacity: 0.5;
    position: absolute;
    bottom: 10px;
    left: 50%;
    color: #fff;
    transform: translateX(-50%);
    font-size: 12px;
    white-space: nowrap;
  }
`;
