import styled from 'styled-components';

export default styled.section`
  line-height: 22px;
  font-size: 16px;

  h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  p {
    margin-bottom: 10px;
  }
`;
