import styled from 'styled-components';

export default styled.div`
  position: relative;

  .car_size {
    position: absolute;
    z-index: 2;
    right: 0px;
    top: 43px;
    border-radius: 5px 0 0 0;
    color: #fff;
    padding: 1px 10px;
    background: ${({ theme }) => theme.green};
  }
`;
