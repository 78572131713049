import styled from 'styled-components';

export default styled.div`
  #select_address_method {
    button {
      padding: 20px;
      border: 1px solid #0e3182;
      text-align: center;
      cursor: pointer;
      box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.5);

      &:hover {
        background: #ccc;
      }

      &:active {
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
        transform: translateY(1px);
      }

      svg {
        display: block;
        margin: 0 auto;
        height: 24px;
        width: 24px;

        path {
          fill: #0e3182;
        }
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
        border-left: none;
      }
    }
  }

  #change_address_method {
    button {
      padding: 10px 20px;
      border: 1px solid #0e3182;
      text-align: center;
      cursor: pointer;
      box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      margin-bottom: 10px;
    }

    svg {
      display: inline-block;
      height: 24px;
      width: 24px;
      vertical-align: middle;
      margin-right: 5px;

      path {
        fill: #0e3182;
      }
    }
  }

  p {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 16px;
    background: #ccc;
    border: 1px solid #000;
    border-radius: 5px 5px 0 0;

    > svg {
      height: 16px;
    }
  }
`;

export const MapWrap = styled.div`
  width: 100%;
  height: 180px;

  > div {
    width: 100%;
    height: 100%;
  }
`;
