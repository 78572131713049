import { Container } from './styles';

import DownloadApp from './components/DownloadApp';
import Footer from './components/Footer';
import Header from './components/Header';
import HowItWorks from './components/HowItWorks';
import Leads from './components/Leads';
import RequestForm from './components/RequestForm';
import Seals from './components/Seals';
import Slide from './components/Slide';
import Washes from './components/Washes';

import React from 'react';

const Index = props => (
  <Container>
    <Header />
    <Slide />
    <Seals />
    <HowItWorks />
    {/* <RequestForm /> */}
    <Washes />
    <DownloadApp />
    {/* <Leads /> */}
    <Footer />
  </Container>
);

export default Index;
