import styled from 'styled-components';

export const Bt = styled.button`
  color: #fff;
  text-transform: uppercase;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  padding: 20px 70px;
  background: #39ca31;
  cursor: pointer;
  transition: 0.2s all;
  border-radius: 8px;
  border: none;
  outline: none;
  display: block;

  @media screen and (max-width: 680px) {
    padding: 10px 35px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: #dfdfdf;
    color: #a5a5a5;
    cursor: default;
  }

  /* CUSTOM STYLES */

  &.bounceInLeft {
    animation: bounceInLeft 1s 0.5s linear;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  @keyframes bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: translate3d(-3000px, 0, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(25px, 0, 0);
    }

    75% {
      transform: translate3d(-10px, 0, 0);
    }
    90% {
      transform: translate3d(5px, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.inline {
    display: inline-block;
    margin: 10px 5px !important;
  }

  &.no-padding {
    padding: 10px 20px !important;
  }
`;
