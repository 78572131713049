import { Input } from './styles';

import React, { useState, useEffect, useRef } from 'react';

export default ({
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  topRightCornerRadius,
  topLeftCornerRadius,
  bottomLeftCornerRadius,
  bottomRightCornerRadius,
  width,
  formikProps,
  icon,
  ref,
  forcedFocus,
  type,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const input = ref || useRef(null);

  useEffect(() => {
    if (input.current.value) setActive(true);
  }, [input]);

  useEffect(() => {
    if (forcedFocus) setActive(true);
  }, [forcedFocus]);

  const { errors, touched } = formikProps
    ? formikProps
    : { erros: null, touched: null };
  const hasIcon = icon ? true : false;

  return (
    <Input
      active={active}
      topLeftCornerRadius={topLeftCornerRadius}
      topRightCornerRadius={topRightCornerRadius}
      bottomLeftCornerRadius={bottomLeftCornerRadius}
      bottomRightCornerRadius={bottomRightCornerRadius}
      width={width}
      hasIcon={hasIcon}
      type={type}
    >
      <label htmlFor={name}>{label}</label>
      {icon && <div className="icon">{icon}</div>}
      <input
        ref={input}
        id={name}
        onFocus={e => {
          setActive(true);
          if (onFocus) onFocus(e);
        }}
        onBlur={e => {
          if (!e.target.value) setActive(false);
          if (onBlur) onBlur(e);
        }}
        onChange={e => {
          if (onChange) onChange(e);
        }}
        type={type}
        {...props}
      />
      {formikProps && errors[name] && touched[name] && (
        <div className="errors">{errors[name]}</div>
      )}
    </Input>
  );
};
