import styled from 'styled-components';

export default styled.section`
  padding: 10px;
  position: fixed;
  min-width: 320px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  max-height: 90vh;
  max-width: 90vw;
  overflow: auto;

  > button {
    position: absolute;
    background: none;
  }
`;

export const Courtain = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(14, 49, 130, 0.5);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
`;
