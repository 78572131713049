import styled, { css } from 'styled-components';

export const Wrap = styled.section`
  width: 100%;
  background: #fff;
  color: #fff;
  min-height: 400px;
  padding: 56px 0;
  text-align: center;

  .container{
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 63px;

    > div{
      flex: 0 1 260px;
      text-align: center;

      @media screen and (max-width: 1060px) {
        margin: 20px auto;
        padding: 0 40px;
      }

      svg{
        margin-bottom: 40px;
      }
    }
  }
}
`;

export const H2 = styled.h2`
  position: relative;
  font-family: Fugaz One;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 68px;
  text-align: center;
  color: #0e3182;

  svg {
    margin: 0 36px;
  }

  /** Estilização para dispositivos móveis */
  @media screen and (max-width: 1060px) {
    display: inline-block;
    margin: 20px auto;

    svg {
      position: absolute;
      margin: 0;
      &:first-child {
        top: -25px;
        left: 0;
      }
      &:last-child {
        top: 55px;
        right: 0;
      }
    }
  }

  @media screen and (max-width: 540px) {
    display: inline-block;
    margin: 20px auto;
    font-size: 35px;

    svg {
      width: 70px;
    }
  }
`;

function cssAnimation() {
  let styles = '';

  for (let row = 0; row < 3; row += 1) {
    for (let col = 0; col < 3; col += 1) {
      styles += `
      .row:nth-child(${row + 1}) .col:nth-child(${col + 1}) .numberIcon{
         animation: bounceIn 1.2s forwards;
         animation-delay: ${(row + 1) * (row + col + 1) * 0.2}s;
       }
     `;
    }
  }

  return css`
    ${styles}
  `;
}

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  max-width: 844px;
  margin: 0 auto;
  margin-top: 60px;

  ${({ visibility }) => visibility && cssAnimation()}

  .row {
    display: flex;
    flex-direction: row;
  }

  .col {
    flex: 1 0;
    justify-content: space-around;
    border: 1px solid #c4c4c4;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    font-size: 14px;
    line-height: 21px;

    &.step {
      padding-top: 35px;
      padding-bottom: 35px;
    }

    p {
      vertical-align: middle;
    }
  }

  .numberIcon {
    width: 46px;
    height: 46px;
    background: #0e3182;
    border: 2px solid #7ac943;
    border-radius: 50%;
    font-family: Fugaz One;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 52px;
    color: #fff;

    position: absolute;
    top: -25px;
    opacity: 0;
    transform-origin: left;
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3) translateX(-50%);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1) translateX(-50%);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9) translateX(-50%);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03) translateX(-50%);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97) translateX(-50%);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1) translateX(-50%);
    }
  }

  /* Arredondando cantos */

  .row:nth-child(1) {
    .col:nth-child(1) {
      border-radius: 30px 0 0 0;
    }
    .col:nth-child(3) {
      border-radius: 0 30px 0 0;
    }
  }

  .row:nth-child(3) {
    .col {
      border-radius: 0 0 30px 30px;
    }
  }

  /* Posicionando números */

  .row:nth-child(1) {
    .col {
      .numberIcon {
        left: 50%;
      }
    }
  }

  .row:nth-child(2) {
    .col {
      &:nth-child(1) {
        .numberIcon {
          left: 33%;
        }
      }

      &:nth-child(2) {
        .numberIcon {
          left: 66%;
        }
      }
    }
  }

  /** Estilização para dispositivos móveis */
  @media screen and (max-width: 1060px) {
    margin: 0 50px;
    margin-top: 60px;
  }

  @media screen and (max-width: 748px) {
    .row {
      flex-wrap: wrap;
    }

    .col {
      flex: 1 0 100%;
    }

    .numberIcon {
      left: 50% !important;
    }

    /* Arredondando cantos */

    .row:nth-child(1) {
      .col:nth-child(1) {
        border-radius: 30px 30px 0 0;
      }
      .col:nth-child(3) {
        border-radius: 0;
      }
    }
  }
`;
