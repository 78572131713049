import styled from 'styled-components';

export default styled.section`
  background: ${({ theme }) => theme.blue};
  color: white;
  padding: 50px 0;

  > section {
    margin-bottom: 40px;
    min-width: 320px;
    max-width: 600px;
    width: 50vw;
    margin: 0 auto;
  }

  h1 {
    font-family: Fugaz One;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #fff;
    font-size: 25px;
  }
`;
