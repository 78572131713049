import Wrap from './styles';

import React from 'react';
import Footer from 'views/Index/components/Footer';

export default ({ children }) => {
  return [
    <Wrap key="wrap">
      <section>{children}</section>
    </Wrap>,
    <Footer key="footer" />,
  ];
};
