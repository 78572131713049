import Wrap, { ProductDetails } from './styles';

import axios from 'axios';
import CenterWrap from 'components/CenterWrap';
import FullScreenLoading from 'components/FullScreenLoading';
import SelectField from 'components/SelectField';
import { useFormik } from 'formik';
import React, { useReducer, useEffect, useState } from 'react';

const sort = (a, b, prop) => {
  // a significa um item, b o seguinte
  // se um título está com a letra anterior da ordem alfabética do que a sua posterior comparada ele deve vir primeiro, por isso retorna -1
  if (a[prop] < b[prop]) {
    return -1;
  }
  // Se o título de a é maior que o de b, ou seja, vem depois no alfabeto, a deve ficar depois de b, por isso retorna 1
  if (a[prop] > b[prop]) {
    return 1;
  }
  // Se forem iguais, tanto faz
  return 0;
};

let initialState = {
  brands: [],
  brand: null,
  models: [],
  model: null,
  products: [],
  product: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadBrandsAndProducts': {
      const { brands, products } = action.payload;
      return { ...state, brands, products };
    }
    case 'loadModels': {
      const { models } = action.payload;
      return { ...state, models };
    }
    case 'setModel': {
      const { model } = action.payload;
      return { ...state, model };
    }
    case 'loadProducts': {
      const { products } = action.payload;
      return { ...state, products };
    }
    default:
      console.log('Chamou o reducer e não fez nada');
  }
};

export default () => {
  let [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);

  const loadModels = brandId => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}`, {
        query: `{ carModels(brandId:${brandId}){ id, name, size{ id, alias } } }`,
      })
      .then(({ data: { data } }) => {
        const models = data.carModels.sort((a, b) => sort(a, b, 'name'));
        dispatch({
          type: 'loadModels',
          payload: { models },
        });
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const setModel = modelId => {
    console.log(state.models);
    console.log(modelId);

    const model = state.models.filter(model => {
      return model.id === Number(modelId);
    });
    dispatch({ type: 'setModel', payload: { model: model[0] } });
  };

  const setProductState = productId => {
    const product = state.products.filter(item => {
      return item.product.id === Number(productId);
    });
    console.log(product[0]);
    setProduct(product[0]);
  };

  useEffect(() => {
    // loads brands and products
    console.log('Carregando marcas de carro');
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}`, {
        query: '{ carBrands{ id, name } products{ id, name, description } }',
      })
      .then(response => {
        const brands = response.data.data.carBrands.sort((a, b) =>
          sort(a, b, 'name')
        );
        const products = response.data.data.products.sort((a, b) =>
          sort(a, b, 'name')
        );
        dispatch({
          type: 'loadBrandsAndProducts',
          payload: { brands, products },
        });
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    console.log(state.model);
    if (state.model) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}`, {
          query: `{
        priceCatalog(carSizeId: ${state.model.size.id}) {
          amount
          carSize {
            alias
          }
          product {
            id
            name
            description
          }
        }
      }`,
        })
        .then(({ data: { data } }) => {
          const products = data.priceCatalog.sort((a, b) =>
            sort(a, b, 'brand')
          );
          dispatch({
            type: 'loadProducts',
            payload: { products },
          });
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [state.model]);

  const formik = useFormik({
    initialValues: {
      brand: null,
      model: null,
      product: null,
    },
  });

  return [
    <FullScreenLoading loading={loading} key="loading-screen" />,
    <CenterWrap key="page">
      <Wrap>
        <h1>Simulador</h1>
        <p>
          O preço de cada um de nossos serviços depende muito do tipo e tamanho
          de carro. Para conhecer cada um de nossos serviços selecione seu carro
          e o serviço que você quer saber mais e fique inteirado de tudo!
        </p>
        <br />
        <SelectField
          label={state.brands?.length ? 'Marca do carro' : 'Carregando...'}
          onChange={e => {
            loadModels(e.target.value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.brand}
          name="brand"
          formikProps={formik}
          width={100}
          topLeftCornerRadius
          topRightCornerRadius
          bottomLeftCornerRadius
          bottomRightCornerRadius
          disabled={false}
        >
          <option value="" />
          {state?.brands &&
            state.brands.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </SelectField>
        <br />
        <br />
        {state.models?.length ? (
          <SelectField
            label="Modelo do carro"
            onChange={e => {
              setModel(e.target.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.model}
            name="model"
            formikProps={formik}
            width={100}
            topLeftCornerRadius
            topRightCornerRadius
            bottomLeftCornerRadius
            bottomRightCornerRadius
            disabled={false}
          >
            <option value="" />
            {state?.models &&
              state.models.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </SelectField>
        ) : null}
        <br />
        <br />
        {state.products?.length && state.model ? (
          <SelectField
            label="Produtos"
            onChange={e => {
              setProductState(e.target.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.product}
            name="product"
            formikProps={formik}
            width={100}
            topLeftCornerRadius
            topRightCornerRadius
            bottomLeftCornerRadius
            bottomRightCornerRadius
            disabled={false}
          >
            <option value="" />
            {state?.products &&
              state.products.map(({ id, product }) => (
                <option key={product?.id} value={product?.id}>
                  {product?.name}
                </option>
              ))}
          </SelectField>
        ) : null}
        {product ? (
          <ProductDetails>
            <h1>{product.product.name}</h1>
            <p>{product.product.description}</p>
            <div>R$ {(product.amount / 100).toFixed(2)}</div>
          </ProductDetails>
        ) : null}
      </Wrap>
    </CenterWrap>,
  ];
};
