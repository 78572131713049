import Wrap from './styles';

import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

export default ({ loading }) => {
  return (
    <Wrap loading={loading}>
      <div>
        <MoonLoader size={150} color={'#fff'} loading={loading} />
      </div>
    </Wrap>
  );
};
