import { Wrap } from '../HowItWorks/styles';

import styled from 'styled-components';

export const RequestWrap = styled(Wrap)`
  color: #3d3d3d;

  h3 {
    font-family: Oxygen;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 54px;
  }

  .tabs {
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  pre {
    text-align: left;
  }

  .steps {
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    overflow-x: hidden;
    padding-bottom: 23px;
    padding-top: 30px;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    transition: 0.5s height;
    overflow-y: hidden;

    > .step {
      max-width: 100%;
      overflow-x: auto;
      flex: 1 0 100%;
      box-sizing: border-box;

      &.step-2 {
        > p {
          font-size: 12px;
          line-height: 20px;
        }

        > button {
          padding: 10px 20px;
          margin: 10px 0 10px 0;
          background: #39ca31;
          color: #fff;
          border-radius: 8px;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }

  pre {
    position: fixed;
    z-index: 99;
    top: 0px;
    background: #fff;
    font-size: 9px;

    &.right {
      right: 0;
    }
  }

  > button {
    margin: 0 auto;
  }

  .errors {
    display: block;
    position: relative;
    color: red;
    font-size: 12px;
  }

  p.small {
    opacity: 0.5;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const StepNavBt = styled.div`
  flex: 1 0 auto;
  justify-content: space-between;

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #3d3d3d;

    span:first-child {
      display: inline-block;
      width: 32px;
      height: 32px;
      border: 1px solid #3d3d3d;
      border-radius: 50%;

      font-family: Oxygen;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
    }

    span:last-child {
      margin-left: 12px;
      line-height: 32px;
      display: inline-block;
      transform: translateY(-4px);
    }

    &:hover {
      span:first-child {
        background: #3d3d3d;
        color: #fff;
      }
    }
  }

  &.error {
    button {
      span:first-child {
        background: red;
        border-color: red;
        color: #fff;
      }
      color: red;
    }
  }

  &.valid {
    button {
      span:first-child {
        background: #7ac943;
        border-color: #7ac943;
        color: #fff;
      }
      color: #7ac943;
    }
  }

  &.active {
    button {
      span:first-child {
        background: #307bb9;
        border-color: #307bb9;
        color: #fff;
      }
    }
  }
`;
