import AppContext from 'services/context';

import Routes from './routes';
import { GlobalStyles } from './theme/styles.App';
import { theme } from './theme/theme';

import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

ReactGA.initialize('UA-170776607-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class App extends React.Component {
  state = {
    nothing: null,
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}
