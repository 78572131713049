import styled from 'styled-components';

export const Wrap = styled.section`
  position: relative;
  width: 100%;
  background: #fff;
  color: #fff;
  min-height: 400px;
  padding: 56px 0 0 0;
  text-align: center;
  background-image: url('/assets/img/pneu.jpeg');

  h2 {
    position: relative;
    z-index: 5;

    div {
      display: inline-block;
      font-family: Fugaz One;
      font-style: normal;
      font-weight: normal;
      font-size: 52px;
      line-height: 68px;
    }
  }

  .courtain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0e3182;
    opacity: 0.8;
    z-index: 2;
  }

  .container {
    position: relative;
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 63px;
    z-index: 5;

    @media screen and (max-width: 840px) {
      width: 90%;
    }

    > div {
      flex: 1 1 50%;
      text-align: center;
      padding: 78px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 21px;

      @media screen and (max-width: 1028px) {
        padding: 68px 30px;
      }

      @media screen and (max-width: 840px) {
        flex: 1 1 100%;
      }

      h1 {
        font-family: Fugaz One;
        font-style: normal;
        font-weight: normal;
        font-size: 150px;
        line-height: 90px;
      }

      h2 {
        font-family: Fugaz One;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 68px;
        margin: 12px 0;
        display: block;
      }
    }

    > div:nth-child(1),
    > div:nth-child(4) {
      background: #0e3182;

      h1 {
        color: #406ac8;
      }
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      background: #ccd3e4;
      color: #000;

      h1 {
        color: #b6bfd5;
      }

      h2 {
        color: #000;
      }
    }

    @media screen and (max-width: 840px) {
      > div:nth-child(1),
      > div:nth-child(3) {
        background: #0e3182;
        color: #fff;

        h1 {
          color: #406ac8;
        }

        h2 {
          color: #fff;
        }
      }

      > div:nth-child(2),
      > div:nth-child(4) {
        background: #ccd3e4;
        color: #000;

        h1 {
          color: #b6bfd5;
        }

        h2 {
          color: #000;
        }
      }
    }

    > figure {
      position: absolute;
      z-index: 5;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 840px) {
        display: none;
      }

      img {
        position: relative;
        width: 157px;
        height: 318px;
        display: inline-block;
      }
    }

    > div:nth-child(1),
    div:nth-child(3) {
      border-right: 7.5px solid #7ac943;
    }

    > div:nth-child(2),
    div:nth-child(4) {
      border-left: 7.5px solid #7ac943;
    }

    @media screen and (max-width: 840px) {
      div:nth-child(1),
      div:nth-child(2),
      div:nth-child(3),
      div:nth-child(4) {
        border: none;
        border-right: 15px solid #7ac943;
      }
    }
  }
`;
