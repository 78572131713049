import styled from 'styled-components';

export default styled.div`
  position: relative;
  background: #ccc;
  border: 1px solid #d5d5d5;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  padding: 10px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #d6d6d6;
  }

  &:active {
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.8);
  }

  h2 {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
  }

  span {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
    background: ${({ theme }) => theme.green};
    border-radius: 5px;
    padding: 1px 5px;
  }

  p {
    font-size: 16px;
    line-height: 18px;
  }
`;
