import React from 'react';

export default props => (
  <svg
    width="129"
    height="136"
    viewBox="0 0 129 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.188 0.928711H16.5251C7.59554 0.928711 0.356689 8.1817 0.356689 17.1287V119.729C0.356689 128.676 7.59554 135.929 16.5251 135.929H112.188C121.118 135.929 128.357 128.676 128.357 119.729V17.1287C128.357 8.1817 121.118 0.928711 112.188 0.928711Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M27.2226 99.8482C26.9045 98.5711 26.0993 97.636 24.5306 97.636C22.2081 97.636 21.239 99.7401 21.239 101.874C21.239 104.009 22.2179 106.113 24.5306 106.113C26.2143 106.113 27.1394 104.818 27.2912 103.199H29.1413C28.9896 105.861 27.1541 107.701 24.5306 107.701C21.283 107.701 19.3423 105.052 19.3423 101.874C19.3423 98.6968 21.2757 96.0422 24.5232 96.0422C26.9706 96.0573 28.8917 97.4424 29.1193 99.8431L27.2226 99.8482Z"
      fill="#47C8EE"
    />
    <path
      d="M32.2036 96.656H34.2066V106.029H37.7156V107.701H32.2036V96.656Z"
      fill="#47C8EE"
    />
    <path
      d="M40.1653 96.656H42.0026V107.701H40.1676L40.1653 96.656Z"
      fill="#47C8EE"
    />
    <path
      d="M52.8253 99.8482C52.4872 98.5711 51.6343 97.636 49.965 97.636C47.4999 97.636 46.4676 99.7401 46.4676 101.874C46.4676 104.009 47.5077 106.113 49.965 106.113C51.754 106.113 52.7369 104.818 52.8981 103.199H54.8639C54.7027 105.861 52.7525 107.701 49.965 107.701C46.517 107.701 44.4524 105.052 44.4524 101.874C44.4524 98.6968 46.5092 96.0422 49.9572 96.0422C52.5574 96.0573 54.5986 97.4424 54.8405 99.8431L52.8253 99.8482Z"
      fill="#47C8EE"
    />
    <path d="M59.1509 96.656H57.3136V107.701H59.1509V96.656Z" fill="#47C8EE" />
    <path
      d="M63.1372 102.074L67.423 96.656H65.0962L60.9883 102.069L65.3691 107.701H67.7251L63.1372 102.074Z"
      fill="#47C8EE"
    />
    <path
      d="M60.9883 27.5293L58.8814 29.6023C58.8814 32.2088 58.8385 41.5779 58.7931 46.9273C58.7679 50.1734 57.2464 51.6296 53.9991 51.7484C47.8426 51.9683 41.7088 51.4551 35.5674 51.2124C33.6877 51.1366 33.236 50.186 33.8289 48.5605C34.6666 46.2574 35.6784 44.0099 36.3849 41.6714C37.2075 38.9436 39.067 37.7276 41.7113 37.4369C44.073 37.1841 55.0538 36.0212 57.418 35.786L57.524 31.5667C55.3843 31.688 44.6407 32.8307 42.5061 33.0127C37.4875 33.4197 33.2385 34.8051 31.5631 40.372C31.0512 41.9535 30.4212 43.494 29.6783 44.9807L28.5051 43.9821L23.4335 44.6268C23.2199 44.6522 23.0199 44.7454 22.8629 44.8928C22.7058 45.0401 22.5999 45.2339 22.5605 45.4459L22.3738 46.4925C22.3323 46.7184 22.3784 46.9517 22.5027 47.1447C22.6269 47.3378 22.8199 47.4761 23.0424 47.5315L27.5513 48.6667C26.4134 49.6223 24.2435 51.1467 22.9667 52.0063C21.362 53.0883 20.3982 54.6127 19.7093 56.3976C18.3468 59.9369 18.1576 63.6608 17.9911 67.3493C17.6374 74.2217 17.9365 81.1122 18.8843 87.9279C19.1164 89.5257 19.8279 90.5773 21.5765 90.5799C26.7666 90.5799 30.6195 90.5799 35.8096 90.5799C36.834 90.5799 37.8938 90.2588 38.0981 89.2627C38.9838 84.965 39.1982 82.715 41.7214 82.4799C42.0418 82.4521 50.5096 82.3509 58.4929 82.2776C59.2054 82.2716 59.8865 81.9831 60.3872 81.4752C60.888 80.9673 61.1676 80.2814 61.1649 79.5675L60.9883 27.5293ZM28.0963 62.0655C27.193 61.1681 26.5572 59.8408 25.9643 57.8917L26.6531 56.9386L38.3782 59.5577C38.5149 59.6417 38.6471 59.7328 38.7743 59.8307L41.2092 62.8492L40.7046 63.9869L28.0963 62.0655Z"
      fill="white"
    />
    <path
      d="M67.3543 79.5674C67.3516 80.2809 67.6308 80.9665 68.131 81.4743C68.6312 81.9821 69.3117 82.2709 70.0238 82.2775C78.0071 82.3509 86.4748 82.452 86.7952 82.4798C89.3184 82.7149 89.5329 84.9548 90.4185 89.2627C90.6229 90.2587 91.6801 90.5798 92.707 90.5798C97.8971 90.5798 101.75 90.5798 106.94 90.5798C108.689 90.5798 109.4 89.5256 109.632 87.9278C110.58 81.1121 110.879 74.2216 110.526 67.3492C110.359 63.6607 110.167 59.9292 108.807 56.3975C108.118 54.6127 107.155 53.0882 105.55 52.0062C104.273 51.1466 102.103 49.6222 100.965 48.6666L105.474 47.5315C105.696 47.4755 105.889 47.3371 106.013 47.1442C106.137 46.9513 106.184 46.7183 106.143 46.4924L105.956 45.4458C105.917 45.2353 105.813 45.0427 105.658 44.8955C105.503 44.7483 105.305 44.6542 105.093 44.6267L100.022 43.982L98.8484 44.9806C98.1053 43.4923 97.4753 41.9501 96.9636 40.3669C95.2882 34.8051 91.0392 33.4146 86.0206 33.0076C83.886 32.8357 73.1399 31.6829 71.0028 31.5615L71.1087 35.7809C73.4729 36.016 84.4537 37.1688 86.8154 37.4318C89.4597 37.7225 91.3193 38.9486 92.1418 41.6663C92.8458 44.0073 93.8576 46.2523 94.6978 48.5554C95.2907 50.1809 94.8391 51.1315 92.9593 51.2073C86.8154 51.4601 80.6816 51.9657 74.5276 51.7433C71.2702 51.6245 69.7487 50.1683 69.7336 46.9222C69.6882 41.5728 69.6453 32.2037 69.6453 29.5972L67.5385 27.5242L67.3543 79.5674ZM87.7919 63.9868L87.2872 62.8492L89.7398 59.8332C89.871 59.7346 89.9921 59.6436 90.1384 59.5601L101.864 56.941L102.552 57.8941C101.959 59.8433 101.324 61.1806 100.42 62.068L87.7919 63.9868Z"
      fill="white"
    />
    <path
      d="M62.3508 26.7683H66.2819V81.5545C66.2819 81.7336 66.2109 81.9053 66.0846 82.0318C65.9583 82.1584 65.7869 82.2295 65.6082 82.2295H63.0245C62.8458 82.2295 62.6745 82.1584 62.5481 82.0318C62.4218 81.9053 62.3508 81.7336 62.3508 81.5545V26.7683Z"
      fill="#7AC943"
    />
    <path
      d="M100.553 96.1137L97.0794 104.978L93.5828 96.0793H92.7298V96.0475H87.9357L88.6037 97.9369H91.5557C91.6444 97.9369 91.7311 97.9637 91.8051 98.014C91.8791 98.0644 91.9371 98.136 91.9719 98.22L95.8257 107.701H105.625L110.596 96.0422L100.553 96.1137Z"
      fill="#7AC943"
    />
    <path
      d="M91.5458 105.796H88.8809L84.6666 96.0422H82.3506L78.3346 105.539C78.2993 105.623 78.2401 105.695 78.1644 105.745C78.0887 105.796 77.9999 105.823 77.909 105.823H71.7145V96.0422H69.5624V107.696H79.3208C79.4121 107.696 79.5015 107.669 79.5773 107.618C79.6532 107.567 79.7121 107.494 79.7464 107.409L83.4478 98.2658L85.2747 102.644H83.2495C83.1572 102.644 83.0672 102.672 82.9909 102.724C82.9145 102.776 82.8555 102.85 82.8212 102.936L82.1972 104.528H86.0546L87.3633 107.672V107.701H87.3765H89.6899H92.2227L91.5458 105.796Z"
      fill="#7AC943"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="34.6793"
        y1="102.313"
        x2="121.776"
        y2="3.25621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F4291" />
        <stop offset="1" stopColor="#47C8EE" />
      </linearGradient>
    </defs>
  </svg>
);
