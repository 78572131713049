import styled from 'styled-components';

export const Wrap = styled.section`
  width: 100%;
  background: #0E3182;
  color: #fff;
  min-height: 400px;

  .container{
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 63px;

    > div{
      flex: 0 1 260px;
      text-align: center;

      @media screen and (max-width: 1060px) {
        margin: 20px auto;
        padding: 0 40px;
      }

      svg{
        margin-bottom: 40px;
      }
    }
  }

}
`;
