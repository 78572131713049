import styled from 'styled-components';

export const Input = styled.div`
  position: relative;
  display: ${({ type }) => (type === 'hidden' ? 'block' : 'inline-block')};
  width: ${({ width }) => (width ? `${width}%` : 'auto')};

  label {
    position: absolute;
    left: ${({ hasIcon }) => (hasIcon ? '50px' : '19px')};
    top: ${({ active }) => (active ? '2px' : '21px')};
    font-size: ${({ active }) => (active ? '12px' : '16px')};
    transition: 0.2s all;
    display: ${({ type }) => (type === 'hidden' ? 'none' : 'block')};
  }

  input {
    padding: 21px 19px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    font-family: Oxygen;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    outline: 0;
    color: ${({ active }) => (active ? '#000' : 'transparent')};
    border-top-left-radius: ${({ topLeftCornerRadius }) =>
      topLeftCornerRadius ? '8px' : '0'};
    border-top-right-radius: ${({ topRightCornerRadius }) =>
      topRightCornerRadius ? '8px' : '0'};
    border-bottom-left-radius: ${({ bottomLeftCornerRadius }) =>
      bottomLeftCornerRadius ? '8px' : '0'};
    border-bottom-right-radius: ${({ bottomRightCornerRadius }) =>
      bottomRightCornerRadius ? '8px' : '0'};
    width: 100%;
    padding-left: ${({ hasIcon }) => (hasIcon ? '50px' : '19px')};
  }

  > .errors {
    position: absolute !important;
    color: red;
    bottom: 2px;
    left: 19px;
    font-size: 12px;
  }

  .icon {
    position: absolute;
    top: 23px;
    left: 19px;
  }
`;
