import { Wrap } from './styles';

import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import ScrollableAnchor from 'react-scrollable-anchor';

export default _props => (
  <ScrollableAnchor id="servicos">
    <ParallaxProvider>
      <Wrap>
        <h2>
          <div>Descrição das nossas</div> <div>Eco-Lavagens Automotivas</div>
        </h2>
        <div className="courtain" />
        <div className="container">
          <div>
            <h1>1</h1>
            <h2>Lavagem Eco-Basic</h2>
            <p>
              <strong>Duração:</strong> aprox. 40 minutos
              <br />
              Limpeza externa de lataria; Limpeza de caixa de rodas; Aplicação
              de pretinho nos pneus.
            </p>
          </div>
          <div>
            <h1>2</h1>
            <h2>Lavagem Eco-Plus</h2>
            <p>
              <strong>Duração:</strong> aprox. 1 hora
              <br />
              Limpeza externa de lataria; Limpeza de caixa de rodas; Aplicação
              de pretinho nos pneus.Limpeza interna de painel, partes plásticas
              e emborrachadas com aplicação de protetor UV; Aspiração interna
              incluindo carpetes e porta-malas.
            </p>
          </div>
          <div>
            <h1>3</h1>
            <h2>Lavagem Eco-Master</h2>
            <p>
              <strong>Duração:</strong> aprox. 1 hora e 20 minutos
              <br />
              Limpeza externa de lataria; Enceramento e polimento com luva em
              microfibra; Limpeza de caixa de rodas; Aplicação de pretinho nos
              pneus; Limpeza interna de painel, partes plásticas e emborrachadas
              com aplicação de protetor UV; Aspiração interna incluindo carpetes
              e porta-malas
            </p>
          </div>
          <div>
            <h1>4</h1>
            <h2>Lavagem Eco-Premium</h2>
            <p>
              <strong>Duração:</strong> aprox. 1 hora e 30 minutos
              <br />
              Limpeza externa de lataria; Enceramento e polimento com luva em
              microfibra; Limpeza de caixas de rodas; Aplicação de pretinho nos
              pneus; Limpeza interna do painel, peças plásticas e embutidas com
              aplicação de protetor UV; Aspiração interna incluindo carpetes e
              porta-malas; Higienização de Ar-Condicionado.
            </p>
          </div>
          <Parallax className="car" y={[0, 120]} tagOuter="figure">
            <img src="/assets/img/car.png" alt="Carro andarilho" />
          </Parallax>
        </div>
      </Wrap>
    </ParallaxProvider>
  </ScrollableAnchor>
);
