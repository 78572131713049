import styled from 'styled-components';

export const Wrap = styled.section`
  width: 100%;
  background: #fafafa;
  color: #fff;
  min-height: 400px;
  padding: 56px 0;
  text-align: center;

  .container {
    position: relative;
    width: 95%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 63px;

    > div {
      flex: 0 1 260px;
      text-align: center;

      @media screen and (max-width: 1060px) {
        margin: 20px auto;
        padding: 0 40px;
      }

      svg {
        margin-bottom: 40px;
      }
    }

    > div:nth-child(2) {
      position: relative;
      background: #fff;
      /* box-shadow: 0 18px 14px rgba(0, 0, 0, 0.2); */
      flex: 0 0 60%;
      color: #000;
      padding-top: 50px;

      svg {
        position: absolute;
        top: -67px;
        left: 50%;
        transform: translateX(-50%);
      }

      p {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 44px;
        padding: 0 12%;
      }

      form {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 40px;

        @media screen and (max-width: 1060px) {
          div {
            width: 100%;
            border-radius: 0;

            &:first-child {
              input {
                border-radius: 8px 8px 0 0;
              }
            }

            &:nth-child(2) {
              input {
                border-radius: 0;
              }
            }
          }
        }
      }

      button {
        margin: 0 auto;
        margin-bottom: 44px;
      }
    }

    > div:first-child,
    > div:last-child {
      flex: 0 0 20%;

      > div {
        background-position: center;
        background-size: cover;
        height: 50%;
      }
    }

    > div:first-child {
      > div:nth-child(1) {
        background-image: url('/assets/img/lavagem_tecnica.png');
      }
      > div:nth-child(2) {
        background-image: url('/assets/img/pneu.jpeg');
        border-bottom-left-radius: 30px;
      }
    }

    > div:last-child {
      > div:nth-child(1) {
        background-image: url('/assets/img/lavagem_tecnica_2.png');
      }
      > div:nth-child(2) {
        background-image: url('/assets/img/lavagem_pneu.png');
        border-bottom-right-radius: 30px;
      }
    }
  }
`;

export const H2 = styled.h2`
  position: relative;
  font-family: Fugaz One;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 68px;
  text-align: center;
  color: #0e3182;

  svg {
    margin: 0 36px;
  }

  /** Estilização para dispositivos móveis */
  @media screen and (max-width: 1060px) {
    display: inline-block;
    margin: 20px auto;

    svg {
      position: absolute;
      margin: 0;
      &:first-child {
        top: -25px;
        left: 0;
      }
      &:last-child {
        top: 55px;
        right: 0;
      }
    }
  }

  @media screen and (max-width: 540px) {
    display: inline-block;
    margin: 20px auto;
    font-size: 35px;

    svg {
      width: 70px;
    }
  }
`;
