import styled from 'styled-components';

export default styled.section`
  label {
    color: black;
  }
`;

export const ProductDetails = styled.div`
  background: ${({ theme }) => theme.green};
  padding: 10px;
  padding: 30px;
  margin-top: 20px;
  padding-bottom: 60px;
  position: relative;

  > p {
    color: ${({ theme }) => theme.blue};
  }

  > div {
    background: #fff;
    color: black;
    padding: 10px;
    font-family: Fugaz One;
    margin-top: 10px;
    display: inline-block;
    color: ${({ theme }) => theme.blue};
    bottom: 0px;
    right: 0px;
    position: absolute;
  }
`;
