import styled, { css } from 'styled-components';

export const Wrap = styled.header`
  position: ${({ scrolled }) => (scrolled ? 'sticky' : 'relative')};
  top: 0;
  width: 100%;
  z-index: 9;
  color: #0e3182;

  .courtain {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: ${({ menuStatus }) => (menuStatus ? 'block' : 'none')};
  }
`;

export const TopBar = styled.div`
  position: relative;
  z-index: 2;
  background: #fff;
  width: 100%;
  overflow: none;
  transition: 0.2s all;
  height: ${({ scrolled }) => (scrolled ? '0px' : '28px')};
  font-size: 14px;
  line-height: 28px;

  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;
    position: relative;

    @media screen and (max-width: 680px) {
      padding: 0 30px;
    }

    span:nth-child(1) {
      text-align: left;

      @media screen and (max-width: 960px) {
        display: none;
      }
    }

    span:nth-child(2) {
      float: right;

      a {
        text-decoration: none;
        color: #0e3182;

        &:hover {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 520px) {
        display: none;
      }
    }
  }
`;

function cssAnimation() {
  let styles = '';

  for (let i = 0; i < 20; i += 1) {
    styles += `
       &:nth-child(${i + 1}){
         animation: bounceInLeft .5s forwards;
         animation-delay: ${i * 0.1}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
}

export const Nav = styled.nav`
  position: relative;
  z-index: 2;
  background: #f7f7f7;
  width: 100%;
  height: 80px;

  .menuToogle {
    width: 50px;
    height: 50px;
    display: none;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    cursor: pointer;
    margin-left: 30px;

    svg {
      path {
        fill: #0e3182;
      }
    }
  }

  @media screen and (max-width: 1060px) {
    .menuToogle {
      display: block;
    }
  }

  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;
    position: relative;

    @media screen and (max-width: 680px) {
      padding: 0 30px;
    }

    ul {
      display: inline-block;
      list-style: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;

      @media screen and (max-width: 1060px) {
        padding-top: 2%;
      }

      li {
        display: inline;
        line-height: 80px;
        margin: 0 0.7vw;
        animation: none;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    ul:nth-of-type(1) {
      text-align: left;

      li:first-child {
        margin-left: 0;
      }
    }

    ul:nth-of-type(2) {
      float: right;
      li:last-child {
        margin-right: 0;
      }
    }

    /** Estilização para dispositivos móveis */
    @media screen and (max-width: 1060px) {
      display: block;
      position: absolute;
      top: 80px;
      background: #fff;
      padding-top: 50px;
      padding-bottom: 50px;
      box-shadow: inset 0 -10px 10px #fff, inset 0 3px 10px #ccc;
      display: ${({ menuStatus }) => (menuStatus ? 'block' : 'none')};

      ul {
        float: none !important;
        display: block;
        padding-top: 0;

        li {
          line-height: 45px;
          display: block;

          &.button {
            a {
              background: #39ca31;
              padding: 5px 12px;
              border-radius: 4px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            }
          }
        }

        &:first-of-type {
          li {
            opacity: 0;
            ${({ menuStatus }) => {
              if (menuStatus) return cssAnimation();
            }};

            @keyframes bounceInLeft {
              from,
              60%,
              75%,
              90%,
              to {
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              }

              0% {
                opacity: 0;
                transform: translate3d(-3000px, 0, 0);
              }

              60% {
                opacity: 1;
                transform: translate3d(25px, 0, 0);
              }

              75% {
                transform: translate3d(-10px, 0, 0);
              }
              90% {
                transform: translate3d(5px, 0, 0);
              }
              to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
              }
            }
          }
        }

        &:nth-of-type(2) {
          opacity: 0.8;
        }
      }
    }
  }

  /** Configurações de quando rolar a página */
  &.scrolled {
    background: #0e3182;
    color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 1060px) {
      color: inherit;
    }

    ul:nth-of-type(2) {
      position: relative;

      @media screen and (min-width: 1060px) {
        left: 60px;
      }
    }

    .menuToogle {
      margin-top: 15px;
      display: none;

      @media screen and (max-width: 1060px) {
        display: inline-block;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const Shield = styled.div`
  position: absolute;
  z-index: 5;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);

  svg {
    transition: 0.2s all;
  }

  .logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  /** Configurações de quando rolar a página */
  &.scrolled {
    top: -70px;

    .logo {
      width: 70px;
      top: 60px;
    }
  }
`;
