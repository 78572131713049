import Button from 'components/Button';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';
import React, { forwardRef } from 'react';

export default forwardRef(({ formikProps, dispatch }, ref) => (
  <div className="step step-1" ref={ref}>
    <TextField
      label="Nome *"
      type="text"
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      value={formikProps.values.name}
      name="name"
      topLeftCornerRadius={true}
      width={50}
      formikProps={formikProps}
    />
    <TextField
      label="DDD + Telefone *"
      type="text"
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      value={formikProps.values.phone}
      name="phone"
      topRightCornerRadius={true}
      width={50}
      formikProps={formikProps}
    />
    <TextField
      label="e-mail *"
      type="text"
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      value={formikProps.values.email}
      name="email"
      bottomLeftCornerRadius={true}
      width={50}
      formikProps={formikProps}
    />
    <SelectField
      label="Local de Atendimento"
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      value={formikProps.values.place}
      name="place"
      bottomRightCornerRadius={true}
      width={50}
      formikProps={formikProps}
    >
      <option value=""></option>
      <option value="1">Camaçari - BA</option>
      <option value="2">Lauro de Freitas - BA</option>
      <option value="3">Salvador - BA</option>
      <option value="4">Outro Lugar</option>
    </SelectField>
    <input
      type="checkbox"
      name="lead"
      id="lead"
      onChange={formikProps.handleChange}
      value={formikProps.values.lead}
      checked={formikProps.values.lead === true}
    />
    <label htmlFor="lead">
      Gostaria de receber novidades sobre o aplicativo Clicklav
    </label>
    <br />
    <Button
      type="button"
      className="inline no-padding"
      onClick={() =>
        dispatch({
          type: 'nextView',
        })
      }
    >
      Próximo
    </Button>
  </div>
));
