import Wrap from './styles';

import CenterWrap from 'components/CenterWrap';
import React from 'react';

export default () => {
  return (
    <CenterWrap>
      <Wrap>
        <h1>CONDIÇOES DE UTILIZAÇÃO</h1>
        <p>
          Seja muito bem vindos CLICKLAV Tecnologias ltda, temos muito prazer em
          tê-lo como cliente.
        </p>
        <p>
          Pedimos que leia com muita atenção o texto abaixo, ele define as
          condições de utilização da nossa plataforma e contratação dos serviços
          disponibilizados.
        </p>
        <p>
          A aceitação integral dos termos abaixo é fundamental para que você
          possa ter acesso às nossa plataforma e contratar os serviços. Assim,
          qualquer dúvida sobre o conteúdo do texto, entre em contato conosco
          através do e-mail comercial@cliclav.com.br para que possamos
          esclarecer sua dúvida.
        </p>
        <p>
          ESCLAREÇA TODAS AS SUAS DÚVIDAS ANTES DE CONTINUAR COM O PROCEDIMENTO
          DE ACEITAÇÃO.
        </p>
        <p>
          É importante reforçar que manifestar seu aceite significa que você
          entendeu e concorda com todas as condições especificadas no texto
          abaixo.
        </p>
        <h2>1. DEFINIÇÕES:</h2>
        <p>Relacionamos abaixo o significado dos termos utilizados no texto:</p>
        <p>
          <strong>PLATAFORMA</strong>: Qualquer conjunto de ferramentas
          tecnológicas (site, aplicativos, redes sociais) posta à disposição dos
          usuários pela CLICKLAV para que os usuários tenham acesso aos serviços
          fornecidos pela empresa.
          <br />
          <strong>SERVIÇO</strong>: Todo e qualquer serviço automotivo, náutico,
          aeronáutico e domestico, posto a disposição dos usuários através da
          plataforma CLICKLAV.
          <br />
          <strong>USUÁRIO</strong>: Cliente juridicamente capaz e devidamente
          cadastrado na plataforma CLICKLAV.
          <br />
          <strong>FORNECEDOR</strong>: Profissionais autônomos (prestadores de
          Serviços Automotivos, Náuticos, Aeronáuticos e Domésticos) que
          utilizam a plataforma como um canal de acesso aos Usuários
          (consumidores do serviço fornecido).
          <br />
          <strong>COOKIE</strong>: Um cookie é um pequeno arquivo que será
          instalado nos dispositivos que acessam nossa plataforma, com o
          objetivo de coletar informações que auxiliam a CLICKLAV a otimizar a
          navegabilidade da nossa plataforma, de forma a tornar a sua
          experiência conosco mais gratificante. A CLICKLAV ASSEGURA QUE OS
          COOKIES QUE UTILIZA NÃO COLETAM DADOS PESSOAIS OU INFORMAÇÕES DE
          QUALQUER NATUREZA ARMAZENADAS NO DISPOSITIVO. A maioria dos
          navegadores permite ao Usuário rejeitar ou aceitar cookies, no
          entretanto é importante observar que os cookies são necessários para
          oferecer certas funcionalidades da nossa plataforma (a exemplo da
          entrega customizada de informações).
          <br />
        </p>
        <h2>2. OBJETO</h2>
        <p>
          A plataforma CLICKLAV foi criada com o intuito de facilitar e otimizar
          o contato entre Fornecedores e Usuários, permitindo que no momento em
          que o usuário demande um serviço automaticamente sejam localizados
          fornecedores próximos oferecendo o serviço solicitado, intermediando a
          negociação e o pagamento do serviço com o Fornecedor.
        </p>
        <p>
          O presente documento estabelece as condições para utilização da
          plataforma CLICKLAV, estabelecendo um contrato entre a CLICKLAV-
          Serviços Tecnológicos Ltda. CNPJ 38.359.312/0001-69 situada a Av.
          Santos Dumont 3092 km 2,5 Centro Comercial MM 502 Sala 102, Lauro de
          Freitas Bahia, cep. 42700.170 e o Usuário, vinculando as partes aos
          direitos e obrigações definidos no presente documento.
        </p>
        <p>
          A CLICKLAV reserva-se o direito de realizar alterações e atualizações
          na plataforma a qualquer momento, a seu critério e circunstância, com
          vistas ao aprimoramento da plataforma, sem a necessidade de
          comunicação prévia ao usuário.
        </p>
        <p>
          A utilização da plataforma CLICKLAV, após alterações e atualizações
          efetuadas, importa em concordância do usuário com as respectivas
          alterações e atualizações. Caso o Usuário não concorde com as
          alterações e atualizações deverão cancelar a conta de cadastro junto à
          CLICKLAV, e cessar toda e qualquer utilização dos Aplicativos. Na
          ausência de manifestação, entender-se-á que o Usuário aceitou
          tacitamente as Condições, e o Contrato continuará vinculando as
          partes.
        </p>
        <p>
          O Usuário concorda em cumprir integralmente as condições definidas
          neste contrato, que vinculam legalmente o Usuário com relação aos
          serviços oferecidos pela CLICKLAV.
        </p>
        <h2>3. CADASTRO</h2>
        <p>
          Para utilização da Plataforma CLICKLAV o Usuário deve ter capacidade
          jurídica para atos civis (Pessoas Jurídicas poderão se cadastrar
          mediante representante legal) e preencher corretamente o cadastro,
          assumindo responsabilidade integral, inclusive perante terceiros, pela
          exatidão e veracidade das informações fornecidas.
        </p>
        <p>
          Todas as informações prestadas pelo Usuário poderão ser verificadas, a
          qualquer momento, pela CLICKLAV, que se reserva o direito de não
          concluir o cadastramento em curso ou bloquear cadastro já existente de
          forma definitiva, ou até a correção das informações, caso o Usuário
          forneça informações incorretas, inverídicas, ou não atenda ao pedido
          de confirmação das informações prestadas.
        </p>
        <p>
          A CLICKLAV reserva-se o direito de impedir, dentro dos seus critérios,
          novos cadastros, ou cancelar os cadastros já existentes, independente
          de prévia notificação ou comunicação, caso identifique qualquer
          anormalidade que, a seu juízo, possa indicar tentativa deliberada de
          burlar as regras de utilização estabelecidas neste termo ou esteja em
          desacordo com a legislação vigente, ou ainda em caso de determinação
          judicial neste sentido.
        </p>
        <p>
          Durante o cadastro o Usuário definirá LOGIN e SENHA para acesso a
          Plataforma e utilização dos serviços, sendo de inteira e exclusiva
          responsabilidade do Usuário qualquer solicitação de serviço que seja
          feita com o uso de LOGIN e SENHA de sua titularidade.
        </p>
        <h2>4. SERVIÇOS</h2>
        <p>
          A plataforma CLICKLAV disponibiliza aos Usuários serviços Automotivos,
          Náutico, Aeronáuticos e Domésticos tais como lavagem, lubrificação,
          polimento, cristalização, limpeza e higienização (a lista completa dos
          serviços disponíveis pode ser verificada na plataforma).
        </p>
        <p>
          A CLICKLAV reserva-se o direito de modificar, a qualquer tempo e
          circunstância, a relação e as condições dos serviços oferecidos,
          podendo modificá-los, excluí-los ou incluir novos serviços sem
          qualquer aviso prévio ao usuário.
        </p>
        <p>
          O Usuário declara estar ciente que o serviço oferecido pela CLICKLAV
          se restringe a intermediação dos serviços oferecidos pelos
          fornecedores através das funcionalidades oferecidas pela plataforma e
          ao Suporte técnico, manutenção e outros serviços em tecnologia da
          informação relacionados a manutenção e desenvolvimento da plataforma,
          não tendo a CLICKLAV NENHUMA RESPONSABILIDADE COM RELAÇÃO AOS SERVIÇOS
          CONTRATADOS ATRAVÉS DA PLATAFORMA.
        </p>
        <h2>5. PAGAMENTO E CANCELAMENTO DE SERVIÇOS</h2>
        <p>
          O pagamento pelos serviços contratados através da plataforma deverá
          ser realizado exclusivamente por intermédio da própria plataforma,
          utilizando as modalidades de pagamento disponíveis no aplicativo.
        </p>
        <p>
          Os custos de serviços ofertados na plataforma já incluem todos os
          impostos e taxas exigidos por lei, de acordo com cada cidade/estado,
          cabendo ao fornecedor a responsabilidade pela emissão da nota fiscal
          relativa ao serviço executado.
        </p>
        <p>
          Os encargos (impostos e taxas) pagos pelo cliente são finais e não
          reembolsáveis.
        </p>
        <p>
          Os serviços contratados através da plataforma poderão ser cancelados
          sem custo pelo cliente até 2h ( duas horas ) antes da hora marcada
          para a prestação dos serviços.
        </p>
        <p>
          Caso o Usuário possua qualquer pendência financeira relativa a
          serviços anteriormente prestados, não poderá solicitar novo serviço
          até que o débito seja regularizado.
        </p>
        <h2>6. LIMITAÇÃO DE RESPONSABILIDADE</h2>
        <p>
          Ao aceitar o presente contrato o usuário está ciente que a CLICKLAV
          não executa nenhum dos serviços oferecidos através da plataforma,
          apenas fornece a plataforma que intermedia a negociação entre o
          Usuário e o Fornecedor. SENDO ASSIM, QUAISQUER DEFEITOS, VÍCIOS,
          PERDAS OU DANOS RELATIVOS A SERVIÇO CONTRATADO ATRAVÉS DA PLATAFORMA
          SÃO DE RESPONSABILIDADE EXCLUSIVA DO RESPECTIVO FORNECEDOR, de forma
          que A CLICKLAV NÃO SE RESPONSABILIZA POR RESSARCIMENTO DE VALORES AO
          USUÁRIO DECORRENTES DO SERVIÇO EXECUTADO.
        </p>
        <p>
          O usuário deverá mandar sua avaliação para o e-mail:
          comercial@clicklav.com.br. Devendo obedecer ao seguinte critério:
          <br /> 1 – Ruim, 2- Aceitável, 3-Regular 4- Bom, 5- ótimo. Serão
          consideradas as médias das avaliações. O usuário poderá solicitar a
          avaliação dos fornecedores pelo mesmo e-mail. Em caso demanda
          judicial, ou uma avaliação abaixo de 3 (três) por mais de 30 dias o
          fornecedor será bloqueado na plataforma.
        </p>
        <p>
          A CLICKLAV não se responsabiliza por falhas que venham a comprometer a
          disponibilidade, desempenho, ou provocar erros ou inconsistências que
          possam interferir no funcionamento da plataforma, embora se comprometa
          a envidar os seus melhores esforços nos sentido de manter a
          disponibilidade, o desempenho, a eficiência e a eficácia da
          plataforma.
        </p>
        <h2>7. PROPRIEDADE INTELECTUAL</h2>
        <p>
          As marcas, nomes, logotipos, nomes de domínio e demais sinais
          distintivos, bem como todo e qualquer conteúdo, desenho, arte ou
          layout utilizado na plataforma constituem propriedade intelectual da
          CLICKLAV, sendo vedada toda e qualquer forma de reprodução, total ou
          parcial, permanente, temporária ou provisória, de forma gratuita ou
          onerosa, sob qualquer modalidade, forma ou título, assim como atos ou
          contribuições tendentes à descompilação, engenharia reversa,
          modificação das características, ampliação, alteração, mesclagem ou
          incorporação total ou parcial de componentes da plataforma em
          quaisquer outros programas ou sistemas.
        </p>
        <h2>8. UTILIZAÇÃO INDEVIDA</h2>
        <p>
          A utilização da plataforma CLICKLAV em desacordo com as condições
          estabelecidas neste contrato implicará na exclusão do cadastro do
          Usuário sem prejuízo das ações judiciais competentes caso o uso
          indevido da plataforma tenha gerado prejuízo a CLICKLAV ou a
          terceiros.
        </p>
        <p>
          No caso de exclusão do usuário por utilização indevida da plataforma,
          os dados cadastrais serão preservados para utilização futura em caso
          de necessidade.
        </p>
        <h2>9. POLÍTICA DE PRIVACIDADE</h2>
        <p>
          A nossa política de privacidade se aplica a todos os usuários e
          fornecedores cadastrados na plataforma.
        </p>
        <p>
          Nós utilizamos cookies para obter e armazenar informações que
          facilitem a usabilidade, navegabilidade e segurança da nossa
          plataforma, a exemplo do IP do Usuário, tipo e versão do browser de
          navegador, modelo e marca do aparelho, operadora de celular, posição
          geográfica, sistema operacional, tempo médio gasto na plataforma,
          páginas visitadas, informações procuradas, horários de acesso, além de
          estatísticas e dados relativos aos serviços solicitados.
        </p>
        <p>
          Os dados obtidos através de informações do cadastro ou de cookies
          podem ser utilizados por empresas parceiras para oferecer serviços e
          experiências individualizadas para os nossos usuários.
        </p>
        <p>
          A CLICKLAV se compromete a não divulgar publicamente as informações do
          Usuário de modo que possam identificá-lo. Todavia, a CLICKLAV pode
          divulgar dados gerais, não nominais, de forma agregada com a
          finalidade de realizar análises, estudos, melhorias no sistema. Dados
          pessoais podem ser divulgados apenas por determinação judicial.
        </p>
        <p>
          A CLICKLAV está fortemente comprometida em manter a privacidade do
          Usuário: São mantidas nos nossos servidores apenas as informações que
          o Usuário se prontifica a fornecer através de formulários e aquelas
          obtidas através de cookies. A menos que o próprio Usuário se
          prontifique a apresentar dados pessoais, a CLICKLAV nunca coletará
          informações com base nas quais Usuários individuais possam ser
          identificados.
        </p>
        <p>
          O aceite implica na permissão do usuário para que a CLICKLAV utilize
          os dados fornecidos no cadastro ou obtidos na plataforma para efetuar
          o processamento das solicitações de serviço, conforme os termos
          definidos neste documento. nesse sentido, cabe ressaltar que os
          usuários e fornecedores terão acesso apenas as informações necessárias
          a contratação dos serviços oferecidos na plataforma e que a avaliação
          do serviço realizada pelo Usuário é confidencial, não sendo divulgada
          de forma individualizada na plataforma.
        </p>
        <p>
          A CLICKLAV possui o dever de confidencialidade de todas as informações
          pessoais e cadastros relativos aos Usuários, assim como valores
          atinentes às operações financeiras advindas da operacionalização dos
          serviços previstos no presente termo porém não responderá pela
          reparação de prejuízos que possam ser derivados de apreensão e
          cooptação de dados por parte de terceiros que, rompendo os sistemas de
          segurança, consigam acessar essas informações.
        </p>
        <p>
          É possível que a CLICKLAV disponibilize conteúdo de terceiros na
          plataforma (vídeos, imagens, artigos, pesquisas de serviços de mídia
          social, etc). Ao acessar o conteúdo desses provedores, cookies de
          terceiros podem vir a ser instalados em seus dispositivos. A CLICKLAV
          não possui controle e gestão sobre o uso, finalidade e funcionamento
          destes cookies, e portanto não se responsabiliza pelas práticas de
          privacidade ou pelo conteúdo dos mesmos.
        </p>
        <h2>
          10. ACEITAÇÃO DAS CONDIÇÕES DE UTILIZAÇÃO E DA POLÍTICA DE
          PRIVACIDADE.
        </h2>
        <p>
          O usuário declara ter lido e compreendido todo o teor do documento,
          aceitando as regras, condições, pagamentos e obrigações estabelecidas
          neste contrato.
        </p>
        <h2>11. ELEIÇÃO DO FORO.</h2>
        <p>
          As partes, em comum acordo, elegem como competente para dirimir
          eventuais controvérsias que venham a surgir da interpretação e do
          cumprimento do presente contrato o foro da Comarca de Lauro de
          Freitas/Ba.
        </p>
      </Wrap>
    </CenterWrap>
  );
};
